"use client";

import { FC, PropsWithChildren } from "react";
import { CookiesProvider as TCookiesProvider } from "react-cookie";
import Cookies from "universal-cookie";

export const CookiesProvider: FC<{ cookies: string } & PropsWithChildren> = ({
  children,
  cookies,
}) => {
  const cookie = new Cookies(cookies);

  return <TCookiesProvider cookies={cookie}>{children}</TCookiesProvider>;
};

"use client";

import { ModalProvider as ModalProviderUI } from "@cloai/uikit";
import { useSelectedLayoutSegments } from "next/navigation";
import { FC, PropsWithChildren } from "react";

import { useRouter } from "@/i18n";

export const ModalProvider: FC<PropsWithChildren> = ({ children }) => {
  const router = useRouter();
  const segments = useSelectedLayoutSegments("modals");

  return (
    <ModalProviderUI router={router} segments={segments}>
      {children}
    </ModalProviderUI>
  );
};

"use client";

import { useSuspenseQuery } from "@apollo/client";
import { FC, PropsWithChildren } from "react";

import { FindPaymentQuery } from "@/.gql/graphql";
import { FIND_PAYMENT } from "@/.graphql";

import { createCustomContext } from "./context";

const [Context, useFindPaymentContext] = createCustomContext<
  FindPaymentQuery["getPayment"]
>({
  name: "FindPaymentContext",
});
export { useFindPaymentContext };

interface IFindPaymentProviderProps extends PropsWithChildren {
  id: string;
}
export const FindPaymentProvider: FC<IFindPaymentProviderProps> = ({
  children,
  id,
}) => {
  const { data } = useSuspenseQuery(FIND_PAYMENT, {
    variables: {
      id,
    },
  });

  return <Context value={data?.getPayment}>{children}</Context>;
};

const gtmCodes: Record<string, string> = {
  "deepnudeai.io": "GTM-NRLL8RNG",
  "deepnudeai.net": "GTM-WWMWWKG5",
  clotheraser: "GTM-P93NBHSG",
  clothoff: "GTM-K76CTFS",
  "localhost:3000": "GTM-K76CTFS",
  nudecity: "GTM-TBWHT8W7",
  nudiva: "GTM-NFQ36QN2",
  removeclothes: "GTM-TSCB56PH",
  undressgirl: "GTM-KMDFPJ3C",
  okbra: "GTM-KMDFPJ3C",
};

export function getGTM(host: string): string | null {
  // Находим первую пару [ключ, значение], где host содержит ключ.
  // Если найдём — возвращаем значение, иначе null.
  return (
    Object.entries(gtmCodes).find(([key]) => host.includes(key))?.[1] ?? null
  );
}

export function sendGTM(data: Record<string, unknown> = {}): void {
  // Проверяем, что мы в браузере и что dataLayer доступен.
  if (typeof window !== "undefined" && window.dataLayer) {
    window.dataLayer.push(data);
  } else {
    console.warn("GTM dataLayer is not available");
  }
}

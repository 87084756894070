import { Text } from "@cloai/uikit";
import { ReactNode } from "react";

export function ToastMessage({
  title,
  text,
}: {
  title?: string;
  text?: string | ReactNode;
}) {
  return (
    <div className="flex flex-col">
      {title && (
        <Text
          text={title}
          color="white"
          fontSize={16}
          fontWeight={700}
          lineHeight={140}
        />
      )}
      {text &&
        (typeof text === "string" ? (
          <Text text={text} color="gray" fontSize={16} lineHeight={140} />
        ) : (
          text
        ))}
    </div>
  );
}

"use client";

import { useSuspenseQuery } from "@apollo/client";

import { RandomPostsQuery } from "@/.gql/graphql";
import { GET_RANDOOM_POSTS } from "@/.graphql/queries";

import { createCustomContext } from "./context";

const [Context, useRandomPostsContext] = createCustomContext<
  RandomPostsQuery["randomPosts"]
>({
  name: "RandomPostsContext",
});
export { Context as RandomPostsContext, useRandomPostsContext };

export function RandomPostsProvider({
  id,
  children,
}: {
  id: string;
  children?: React.ReactNode;
}) {
  const { data } = useSuspenseQuery(GET_RANDOOM_POSTS, {
    context: {
      clientName: "blog",
    },
    variables: {
      notId: id,
    },
  });

  return <Context value={data?.randomPosts}>{children}</Context>;
}

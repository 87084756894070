"use client";

import { useSuspenseQuery } from "@apollo/client";
import { filter, find, findIndex, maxBy } from "lodash";
import { useParams } from "next/navigation";
import {
  Dispatch,
  FC,
  PropsWithChildren,
  SetStateAction,
  useState,
} from "react";
import { useCookies } from "react-cookie";

import { FindPaymentQuery, GetMethodsQuery } from "@/.gql/graphql";
import { GET_PAYMENT } from "@/.graphql";
import { getCountry } from "@/utils/headers";

import { createCustomContext } from "./context";
import { useHeadersContext } from "./HeadersProvider";

/**
 * Тип для объекта `Payment`, получаемого из запроса `GraphQL`.
 * Определён на основе типа, генерируемого `Apollo`.
 */
export type TPayment = FindPaymentQuery["getPayment"];

/**
 * Тип для платёжного метода.
 */
export type TMethod = GetMethodsQuery["getMethods"][0];

/**
 * Тип для набора монет.
 */
export type TCoinPack = GetMethodsQuery["getPacks"][0];

/**
 * Тип для подписки.
 */
export type TSubscribe = GetMethodsQuery["getSubscribe"];

/**
 * Тип для плана подписки.
 */
export type TSubscribePlan = GetMethodsQuery["getSubscribePlans"][0];

/**
 * Интерфейс пропсов контекста оплаты.
 *
 * @property {TCoinPack} coinPack - Текущий выбранный набор монет, определяемый по параметрам URL.
 * @property {number} coinPackIndex - Индекс текущего набора монет в общем массиве паков.
 * @property {string} country - Текущая страна пользователя, определяемая по куки или заголовкам.
 * @property {TMethod[]} methodsList - Список всех доступных платёжных методов.
 * @property {TCoinPack[]} allPacksList - Список всех паков монет (без фильтрации).
 * @property {TCoinPack[]} userPacksList - Список обычных пользовательских паков монет (без `isPromo`), отфильтрованных из общей выборки.
 * @property {TCoinPack[]} userPromoPacks - Список промо-паков монет.
 * @property {TCoinPack[]} dealerPacksList - Список диллерских паков монет (`isDealer`), отфильтрованных из общей выборки.
 * @property {boolean} isCrypto - Флаг, указывающий, используется ли криптовалютный метод оплаты.
 * @property {boolean} loading - Флаг загрузки (например, для отображения спиннера при обработке).
 * @property {number} maxBonus - Максимальный бонус по скидкам среди первых четырёх стандартных паков монет.
 * @property {number} maxCoins - Максимальное кол-во коинов среди первых четырёх стандартных паков монет.
 * @property {Dispatch<SetStateAction<string>>} setCountry - Сеттер для изменения страны.
 * @property {Dispatch<SetStateAction<boolean>>} setCrypto - Сеттер для изменения флага использования криптовалюты.
 * @property {Dispatch<SetStateAction<boolean>>} setLoading - Сеттер флага загрузки.
 * @property {TSubscribe} subscribe - Данные по подписке.
 * @property {TSubscribePlan[]} subscribePlans - Список доступных планов подписки.
 */
interface TPaymentContextProps {
  coinPack: TCoinPack;
  coinPackIndex: number;
  country: string;
  methodsList: TMethod[];
  allPacksList: TCoinPack[];
  userPacksList: TCoinPack[];
  userPromoPacks: TCoinPack[];
  dealerPacksList: TCoinPack[];
  isCrypto: boolean;
  loading: boolean;
  maxBonus: number;
  maxCoins: number;
  setCountry: Dispatch<SetStateAction<string>>;
  setCrypto: Dispatch<SetStateAction<boolean>>;
  setLoading: Dispatch<SetStateAction<boolean>>;
  subscribe: TSubscribe;
  subscribePlans: TSubscribePlan[];
}

/**
 * Контекст оплаты, обеспечивающий доступ к состоянию и данным о методах оплаты, наборах монет,
 * промо-предложениях, стране пользователя и прочих связанных с оплатой данных.
 */
const [Context, usePaymentContext] = createCustomContext<TPaymentContextProps>({
  name: "PaymentContext",
});
export { usePaymentContext };

/**
 * Провайдер контекста оплаты `PaymentProvider`.
 *
 * Данный провайдер:
 * - Определяет текущую страну пользователя, используя куки или заголовки.
 * - Выполняет GraphQL-запрос для получения платёжных методов, паков монет, промо-паков и подписок.
 * - Предоставляет функции для изменения состояния (страны, флага криптовалюты, флага загрузки).
 * - Определяет текущий выбранный пакет монет на основе параметров URL.
 *
 * Применение:
 * Оберните страницу или раздел приложения, связанные с оплатой, в данный провайдер.
 * Компоненты, расположенные внутри, смогут использовать `usePaymentContext` для доступа к данным о платёжных методах,
 * наборах монет, подписках и прочей платёжной информации.
 */
export const PaymentProvider: FC<PropsWithChildren> = ({ children }) => {
  // Получаем параметры из URL (например, количество монет при выборе пакета)
  const params = useParams();

  // Получаем объект заголовков из useHeadersContext (предоставляет заголовки запроса)
  const headers = useHeadersContext();

  // Считываем куки, чтобы определить страну (если в куках нет, получаем через getCountry из заголовков)
  const [cookies] = useCookies(["country"]);
  const $country = cookies.country ?? getCountry(headers);

  // Локальное состояние для страны, крипто-флага и загрузки
  const [country, setCountry] = useState($country);
  const [isCrypto, setCrypto] = useState(false);
  const [loading, setLoading] = useState(false);

  // Выполняем GraphQL-запрос для получения платёжных данных
  const { data } = useSuspenseQuery(GET_PAYMENT);

  // Общий список пакетов монет без фильтрации
  const allPacksList = data.getPacks;

  // Фильтруем пользовательские пакеты монет, чтобы получить только обычные (не промо)
  const userPacksList = filter(allPacksList, {
    isForDealers: false,
    isPromo: false,
  });

  // Получаем список промо-паков
  const userPromoPacks = filter(userPacksList, { isPromo: true });

  // Фильтруем диллерские пакеты монет
  const dealerPacksList = filter(allPacksList, {
    isForDealers: true,
  });

  // Находим максимальный бонус (discount) среди пользовательских паков
  const maxBonus = maxBy(userPacksList, (pack) => pack.discount)?.discount ?? 0;

  // Находим максимальное кол-во коинов (coin) среди первых четырёх обычных паков
  const maxCoins = maxBy(userPacksList, (pack) => pack.coin)?.coin ?? 0;

  // Определяем текущий выбранный пакет монет на основе URL-параметра "coin"
  const coinPack = find(allPacksList, [
    "coin",
    Number(params?.coin),
  ]) as TCoinPack;

  // Индекс этого пакета
  const coinPackIndex = findIndex(allPacksList, ["coin", Number(params?.coin)]);

  // Данные о подписке
  const subscribe = data?.getSubscribe;

  return (
    <Context
      value={{
        coinPack,
        coinPackIndex,
        country,
        methodsList: data?.getMethods,
        allPacksList,
        userPacksList,
        userPromoPacks,
        dealerPacksList,
        isCrypto,
        loading,
        maxBonus,
        maxCoins,
        setCountry,
        setCrypto,
        setLoading,
        subscribe,
        subscribePlans: data?.getSubscribePlans ?? [],
      }}
    >
      {children}
    </Context>
  );
};

"use client";

import { useSuspenseQuery } from "@apollo/client";

import { WithdrawalsContextQuery } from "@/.gql/graphql";
import { GET_WITHDRAWALS } from "@/.graphql/queries";

import { createCustomContext } from "./context";

const [Context, useWithdrawalsContext] = createCustomContext<
  WithdrawalsContextQuery["getWithdrawals"]
>({
  name: "WithdrawalsContext",
});
export { useWithdrawalsContext };

export function WithdrawalsProvider({
  children,
}: {
  children?: React.ReactNode;
}) {
  const { data } = useSuspenseQuery<{
    getWithdrawals: WithdrawalsContextQuery["getWithdrawals"];
  }>(GET_WITHDRAWALS);

  return <Context value={data?.getWithdrawals}>{children}</Context>;
}

"use client";

import { FC, PropsWithChildren } from "react";
import { useCookies } from "react-cookie";

import { createCustomContext } from "./context";
import { useProfileContext } from "./ProfileProvider";

interface IProps {
  EmailTriggerFree: boolean | null;
  EmailTriggerVip: boolean | null;
  AB_anim_group_1: boolean | null;
  AB_anim_group_1_key_0: boolean | null;
  AB_anim_group_1_key_1: boolean | null;
  AB_anim_group_2: boolean | null;
  AB_anim_group_2_key_0: boolean | null;
  AB_anim_group_2_key_1: boolean | null;
  AB_Payment_HUB: boolean | null;
}

const [Context, useABtestContext] = createCustomContext<IProps>({
  name: "ABtestContext",
});
export { useABtestContext };

export const ABtestProvider: FC<PropsWithChildren> = ({ children }) => {
  const profile = useProfileContext();
  // const headers = useHeadersContext();
  // const [cookies] = useCookies(["country"]);
  // const country = cookies.country ?? getCountry(headers);
  const [cookies] = useCookies(["subscribeEmailIsShowed"]);

  /**
   * Триггеры для попапа с email
   */
  const EmailTriggerFree =
    !cookies.subscribeEmailIsShowed &&
    profile &&
    !profile.isEmailSubscribed &&
    profile.coins < 1;

  const EmailTriggerVip =
    !cookies.subscribeEmailIsShowed &&
    profile &&
    !profile.isEmailSubscribed &&
    profile.paymentCount > 0 &&
    profile.coins > 0;

  /**
   * Группа триггеров для AB-теста по функции `Animate`
   */
  const AB_anim_group_1 =
    process.env.NEXT_PUBLIC_IS_LOCAL_TEST === "true" &&
    profile &&
    (profile.segment_ab === 1 || profile.segment_ab === 2);

  const AB_anim_group_1_key_0 =
    process.env.NEXT_PUBLIC_IS_LOCAL_TEST === "true" &&
    profile &&
    (profile.segment_ab === 1 || profile.segment_ab === 2) &&
    profile?.ga_ab === 0;

  const AB_anim_group_1_key_1 =
    process.env.NEXT_PUBLIC_IS_LOCAL_TEST === "true" &&
    profile &&
    (profile.segment_ab === 1 || profile.segment_ab === 2) &&
    profile?.ga_ab === 1;

  const AB_anim_group_2 =
    process.env.NEXT_PUBLIC_IS_LOCAL_TEST === "true" &&
    profile &&
    (profile.segment_ab === 3 || profile.segment_ab === 4);

  const AB_anim_group_2_key_0 =
    process.env.NEXT_PUBLIC_IS_LOCAL_TEST === "true" &&
    profile &&
    (profile.segment_ab === 3 || profile.segment_ab === 4) &&
    profile?.ga_ab === 0;

  const AB_anim_group_2_key_1 =
    process.env.NEXT_PUBLIC_IS_LOCAL_TEST === "true" &&
    profile &&
    (profile.segment_ab === 3 || profile.segment_ab === 4) &&
    profile?.ga_ab === 1;

  /**
   * Локально текстим платежный хаб
   */
  const AB_Payment_HUB = profile && profile.ga_ab === 1;

  return (
    <Context
      value={{
        EmailTriggerFree,
        EmailTriggerVip,
        AB_anim_group_1,
        AB_anim_group_1_key_0,
        AB_anim_group_1_key_1,
        AB_anim_group_2,
        AB_anim_group_2_key_0,
        AB_anim_group_2_key_1,
        AB_Payment_HUB,
      }}
    >
      {children}
    </Context>
  );
};

"use client";

import { useSubscription, useSuspenseQuery } from "@apollo/client";
import { useTranslations } from "next-intl";
import { FC, PropsWithChildren } from "react";
import { toast } from "react-toastify";

import { AiRequests, AiRequestsQuery } from "@/.gql/graphql";
import { GET_AI_REQUESTS, UPDATE_AI_REQUESTS } from "@/.graphql";
import { ToastIcon } from "@/components/ToastIcon";
import { ToastMessage } from "@/components/ToastMessage";

import { createCustomContext } from "./context";

/**
 * Контекст для хранения и обновления данных AI запросов
 * @type {[React.Context<AiRequests>, () => AiRequests]}
 */
const [Context, useAiRequestsContext] = createCustomContext<AiRequests>({
  name: "AiRequestsContext",
});
export { useAiRequestsContext };

/**
 * Провайдер для управления AI запросами
 * @component
 * @description Предоставляет контекст с данными AI запросов и обрабатывает их обновления через подписку
 *
 * Функциональность:
 * - Получает начальные данные через GET_AI_REQUESTS
 * - Подписывается на обновления через UPDATE_AI_REQUESTS
 * - Отображает уведомления при появлении новых элементов
 * - Обновляет кэш Apollo при получении новых данных
 *
 * @example
 * ```tsx
 * <AiRequestsProvider>
 *   <YourComponent />
 * </AiRequestsProvider>
 * ```
 */
export const AiRequestsProvider: FC<PropsWithChildren> = ({ children }) => {
  /** Получаем функции для локализации уведомлений */
  const tToast = useTranslations("Notifications.toastAiRequests");

  /** Получаем начальные данные через запрос */
  const { data } = useSuspenseQuery(GET_AI_REQUESTS);

  /**
   * Подписываемся на обновления AI запросов
   * @param {Object} subscriptionData - Данные, полученные через подписку
   * @param {ApolloClient} client - Экземпляр Apollo клиента
   */
  useSubscription(UPDATE_AI_REQUESTS, {
    onData: ({ data: subscriptionData, client }) => {
      if (subscriptionData.data?.updateAiRequests) {
        client.cache.updateQuery<AiRequestsQuery>(
          { query: GET_AI_REQUESTS },
          (previousData) => {
            const newData = subscriptionData.data?.updateAiRequests;

            if (!newData) {
              return previousData;
            }

            // Проверяем наличие новых элементов
            if (previousData?.getAiRequests) {
              const hasNewItems = Object.keys(newData).some((key) => {
                const typedKey = key as keyof AiRequests;
                const prevItems = previousData.getAiRequests[typedKey] || [];
                const newItems = newData[typedKey] || [];

                return newItems.length > prevItems.length;
              });

              // Показываем уведомление если есть новые элементы
              if (hasNewItems) {
                void toast.info(
                  <ToastMessage
                    title={tToast("title")}
                    text={tToast("text")}
                  />,
                  {
                    icon: () => ToastIcon("lock_open_right"),
                  },
                );
              }
            }

            // Обновляем кэш новыми данными
            return {
              getAiRequests: newData,
            };
          },
        );
      }
    },
  });

  return <Context value={data?.getAiRequests}>{children}</Context>;
};

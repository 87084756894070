"use client";

import { FC, PropsWithChildren, useEffect } from "react";

import { usePathname } from "@/i18n";

export const LocationProvider: FC<PropsWithChildren> = (props) => {
  const pathname = usePathname();

  useEffect(() => {
    const storage = window?.sessionStorage;

    const prevPath = storage.getItem("currentPath") ?? "";
    storage.setItem("prevPath", prevPath);
    storage.setItem("currentPath", pathname as string);
  }, [pathname]);

  return props.children;
};

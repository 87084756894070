"use client";

import { SessionProvider } from "next-auth/react";
import {
  Dispatch,
  FC,
  PropsWithChildren,
  SetStateAction,
  useState,
} from "react";

import { createCustomContext } from "./context";

interface LoginContextProps {
  policyChecked: boolean;
  setPolicyChecked: Dispatch<SetStateAction<boolean>>;
}

const [Context] = createCustomContext<LoginContextProps>({
  name: "LoginContext",
});

export const AuthProvider: FC<PropsWithChildren> = ({ children }) => {
  const [policyChecked, setPolicyChecked] = useState(false);

  return (
    <Context
      value={{
        policyChecked,
        setPolicyChecked,
      }}
    >
      <SessionProvider>{children}</SessionProvider>
    </Context>
  );
};

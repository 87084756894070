"use client";

import { useSuspenseQuery } from "@apollo/client";
import dayjs from "dayjs";
import isBetween from "dayjs/plugin/isBetween";
import timezone from "dayjs/plugin/timezone";
import utc from "dayjs/plugin/utc";
import _ from "lodash";
import { FC, PropsWithChildren, useEffect, useMemo, useState } from "react";

import { GetBonusesResponse } from "@/.gql/graphql";
import { GET_BONUSES } from "@/.graphql";

import { createCustomContext } from "./context";

dayjs.extend(utc);
dayjs.extend(timezone);
dayjs.extend(isBetween);

/**
 * Тип, описывающий контекст для работы с подарками в ивенте.
 */
export type TGiftContextProps = {
  /**
   * Флаг, указывающий, можно ли забрать подарок текущего дня.
   * `true` — если подарок доступен для получения (статус подарка == 0).
   */
  hasToClaimGift: boolean;

  /**
   * Флаг, указывающий, завершён ли процесс получения всех подарков.
   * `true` — если последний подарок уже забран (статус последнего подарка == 1).
   */
  isFinish: boolean;

  /**
   * Номер текущего "дня" ивента, определяемый по датам доступности подарков.
   * Если подарок сегодняшнего дня не найден, значение будет 1 по умолчанию.
   */
  day: number;

  /**
   * Список всех доступных подарков ивента.
   * Данные получаем из GraphQL-запроса `GET_BONUSES`.
   */
  giftsList: GetBonusesResponse["bonuses"];

  /**
   * Подарок, соответствующий текущему дню ивента.
   * Если он не найден, будет `undefined`.
   */
  todayGift: GetBonusesResponse["bonuses"][0] | undefined;

  /**
   * Время до разблокировки следующего подарка.
   * Строка времени, например "2024-12-16T12:34:56Z".
   * Если следующий подарок не определён, может быть неиспользуемым значением.
   */
  timeToNextGift: string;

  /**
   * Список подарков, которые пользователь забрал, но ещё не активировал.
   * Можно применить эти подарки для генерации (функционал ивента).
   */
  claimedUndressGift: GetBonusesResponse["bonuses"];

  /**
   * Функция для сброса интервала и обновления текущего времени.
   * Вызывается, когда нужно рефрешнуть данные о времени или подарках.
   */
  onResetInterval: () => void;

  /**
   * Текущее серверное время, полученное из GraphQL.
   * Строка даты/времени в формате ISO.
   */
  nowTime: string;
};

/**
 * Контекст для работы с подарками ивента.
 * Содержит информацию о том, какой подарок сейчас доступен, какие подарки уже забраны, время до следующего подарка и т.д.
 */
const [Context, useGiftContext] = createCustomContext<TGiftContextProps>({
  name: "GiftContext",
});
export { useGiftContext };

/**
 * Провайдер контекста `GiftProvider`.
 * Получает данные о подарках и времени с сервера (через GraphQL-запрос `GET_BONUSES`),
 * вычисляет текущий подарок дня, статус и прочую информацию.
 * Предоставляет эти данные дочерним компонентам через контекст useGiftContext.
 *
 * @param {PropsWithChildren} props Пропсы компонента. Ожидает children.
 * @returns JSX.Element
 *
 * Пример использования:
 * ```typescript
 * <GiftProvider>
 *   <SomeChildComponent />
 * </GiftProvider>
 * ```
 */
export const GiftProvider: FC<PropsWithChildren> = ({ children }) => {
  // Выполняем GraphQL-запрос для получения списка бонусов (подарков)
  const { data, refetch } = useSuspenseQuery(GET_BONUSES);

  // Извлекаем подарки и серверное время из ответа
  const { bonuses: giftsList, serverTime } = data?.getBonuses ?? {
    bonuses: [],
    serverTime: dayjs().tz("Europe/London").format(),
  };

  // Сохраняем текущее время в состоянии
  const [nowTime, setNowtTime] = useState(serverTime);

  useEffect(() => {
    // Обновляем текущее время, когда серверное время меняется
    setNowtTime(serverTime);
  }, [serverTime]);

  // Проверяем, завершён ли ивент (если статус последнего подарка == 1)
  const isFinish = _.last(giftsList)?.status === 1;

  /**
   * Определяем индекс сегодняшнего подарка:
   * - Если ивент завершён, todayGiftIndex будет индексом последнего подарка.
   * - Иначе ищем подарок, доступный сейчас по времени (между claimAvailableAt текущего и следующего подарка).
   */
  const todayGiftIndex = useMemo(
    () =>
      Math.max(
        _.findIndex(giftsList, (item, Index) => {
          if (Index === giftsList.length - 1) {
            // Последний подарок всегда считается доступным при завершении или в конце ивента
            return true;
          }
          return (
            dayjs(nowTime).format() >= dayjs(item.claimAvailableAt).format() &&
            dayjs(nowTime).format() <
              dayjs(giftsList[Index + 1]?.claimAvailableAt).format()
          );
        }),
        isFinish ? giftsList.length - 1 : 0,
      ),
    [giftsList, isFinish, nowTime],
  );

  // Получаем подарок текущего дня по индексу
  const todayGift = giftsList[todayGiftIndex]!;

  // Флаг, указывающий, что нужно забрать подарок (статус == 0)
  const hasToClaimGift = useMemo(
    () => todayGift?.status === 0,
    [todayGift?.status],
  );

  // День текущего подарка или 1 по умолчанию
  const day = todayGift?.day ?? 1;

  /**
   * Время до:
   * - если подарок текущего дня не забран - до кончания дедлайна текущего подарка (дата/время `todayGift.deadlineAt`)
   * - если подарок текущего дня уже забран - до времени старта доступности подарка следующего дня (дата/время `giftsList?.[todayGiftIndex + 1]?.claimAvailableAt`)
   */
  const timeToNextGift = dayjs(
    todayGift?.status === 0
      ? todayGift?.deadlineAt
      : giftsList?.[todayGiftIndex + 1]?.claimAvailableAt,
  )
    .tz("Europe/London")
    .format();

  // Список подарков, которые забраны (status == 1), но ещё не активированы (activatedAt отсутствует)
  const claimedUndressGift = useMemo(
    () =>
      _.filter(
        giftsList,
        (item) =>
          // NOTE: Подарок забран и не использован
          item?.status === 1 && !item?.activatedAt,
      ),
    [giftsList],
  );

  /**
   * Функция для сброса интервала и обновления времени:
   * Вызывает refetch для обновления данных с сервера,
   * и после получения новых данных обновляет nowTime.
   */
  const onResetInterval = async () => {
    try {
      const { data: newData } = await refetch();
      const { serverTime } = newData.getBonuses ?? {
        serverTime: dayjs().tz("Europe/London").format(),
      };
      setNowtTime(serverTime);
    } catch (error) {
      console.error(error);
    }
  };

  // Возвращаем провайдер контекста с вычисленными значениями
  return (
    <Context
      value={{
        hasToClaimGift,
        isFinish,
        day,
        giftsList,
        todayGift,
        timeToNextGift,
        claimedUndressGift,
        onResetInterval,
        nowTime,
      }}
    >
      {children}
    </Context>
  );
};

"use client";

import React, { useEffect, useState, PropsWithChildren } from "react";

import OneSignal from "@/utils/oneSignal";

import { createCustomContext } from "./context";
import { useHeadersContext } from "./HeadersProvider";
import { useProfileContext } from "./ProfileProvider";

interface OneSignalContextValue {
  initialized: boolean;
  error: Error | null;
}

const [Context] = createCustomContext<OneSignalContextValue>({
  name: "OneSignalContext",
});

const ONE_SIGNAL_CONFIG = {
  "beta.clothoff.net": {
    appId: "f05d08c6-06c6-486c-bce6-5a75f96e4407",
  },
  "next.clothoff.net": {
    appId: "b2c9a986-cd68-474d-a5d0-0690ecfcb776",
  },
  "clothoff.io": {
    appId: "d90c321b-405a-4aa5-945f-c5a358f09a14",
  },
} as Record<string, { appId: string }>;

export const OneSignalProvider: React.FC<PropsWithChildren> = ({
  children,
}) => {
  const { host } = useHeadersContext();
  const profile = useProfileContext();
  const [initialized, setInitialized] = useState(false);
  const [error, setError] = useState<Error | null>(null);

  const appId = ONE_SIGNAL_CONFIG[host]?.appId;

  useEffect(() => {
    // Убеждаемся, что код выполняется только на клиентской стороне
    if (typeof window !== "undefined" && profile && !initialized && appId) {
      OneSignal.init({
        appId,
        notifyButton: {
          enable: true,
          displayPredicate: () => !OneSignal.Notifications.permission,
        },

        promptOptions: {
          type: "push",
          autoPrompt: true,
        },
        // allowLocalhostAsSecureOrigin: true // при необходимости для локальной разработки
      })
        .then(async () => {
          OneSignal.Debug.setLogLevel("none");
          // await OneSignal.login(profile.id);
          // OneSignal.User.addAlias("userId", profile.id);
          OneSignal.User.addTag("userId", profile.id);

          setInitialized(true);
        })
        .catch((initError) => {
          console.error("OneSignal initialization failed: ", initError);
          setError(initError);
        });
    }
  }, [appId, initialized, profile]);

  return <Context value={{ initialized, error }}>{children}</Context>;
};

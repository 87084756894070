import { graphql } from "@/.gql";

// export const UPDATE_UNDRESS = graphql(`
//   subscription updateUndress {
//     updateUndress {
//       ...TUndress
//     }
//   }
// `);'

export const NEW_NOTIFICATION = graphql(`
  subscription newNotification {
    newNotification {
      ...NotificationFragment
    }
  }
`);

export const REDIRECTION_NOTIFICATION = graphql(`
  subscription redirectionNotification {
    redirectionNotification {
      url
    }
  }
`);

export const UPDATE_PROFILE = graphql(`
  subscription updateProfile {
    updateProfile {
      ...ProfileUpdatedFragment
    }
  }
`);

export const UPDATE_BONUS = graphql(`
  subscription updateBonus {
    updateBonus {
      ... on CoinsUserBonus {
        activatedAt
        claimAvailableAt
        coins
        createdAt
        day
        deadlineAt
        description
        id
        status
        takenAt
        type
      }
      ... on CostumeUserBonus {
        activatedAt
        claimAvailableAt
        costumeId
        costumeImage
        costumeName
        createdAt
        day
        deadlineAt
        description
        id
        status
        takenAt
        type
      }
      ... on FreeCoinsUserBonus {
        activatedAt
        claimAvailableAt
        createdAt
        day
        deadlineAt
        description
        freeCoins
        id
        status
        takenAt
        type
      }
      ... on PoseUserBonus {
        activatedAt
        claimAvailableAt
        createdAt
        day
        deadlineAt
        description
        id
        poseId
        poseImage
        poseName
        status
        takenAt
        type
      }
      ... on PromoCodeUserBonus {
        activatedAt
        claimAvailableAt
        createdAt
        day
        deadlineAt
        description
        id
        promoCode {
          ... on UserPromoCodeCoins {
            coins
            id
            promoCode
            type
          }
          ... on UserPromoCodeFreeCoins {
            freeCoins
            id
            promoCode
            type
          }
        }
        status
        takenAt
        type
      }
    }
  }
`);

export const UPDATE_AI_REQUESTS = graphql(`
  subscription UpdateAiRequests {
    updateAiRequests {
      ...TAiRequestList
    }
  }
`);

import { defineRouting, Pathnames } from "next-intl/routing";
import { NextRequest } from "next/server";

/** Для китая мы скрываем все остальные локали */
const isCN = process.env.NEXT_PUBLIC_IS_CN;

/** Список доступный локалей с именами языков */
export const langList = [
  { key: "en", value: "English" },
  { key: "es", value: "Español" },
  { key: "ja", value: "日本語" },
  { key: "pt", value: "Português" },
  { key: "it", value: "Italiano" },
  { key: "tr", value: "Türkçe" },
  { key: "hi", value: "हिंदी" },
  { key: "zh", value: "中文" },
  { key: "ar", value: "اللغة العربية" },
  { key: "de", value: "Deutsch" },
  { key: "fr", value: "Français" },
  { key: "id", value: "Bahasa Indonesia" },
  { key: "ko", value: "한국어" },
  { key: "ru", value: "Русский" },
];

/** Список ключе локалей */
export const locales = isCN ? ["zh"] : [...new Set(langList.map((l) => l.key))];
export const pathnames: Pathnames<typeof locales> = {};

/** Статический конфиг роутинга */
export const routing = defineRouting({
  locales,
  defaultLocale: isCN ? "zh" : "en",
  alternateLinks: false,
  localeDetection: false,
  localePrefix: "always",
});

// Функция для получения конфигура с учетом динамических параметров
export function getRoutingConfig(request: NextRequest) {
  const $cookies = request.cookies;
  const $defaultLocale = isCN ? "zh" : "en";
  const defaultLocale = isCN
    ? "zh"
    : ($cookies.get("NEXT_LOCALE")?.value ?? "en");

  return defineRouting({
    ...routing,
    localePrefix: defaultLocale === $defaultLocale ? "as-needed" : "always",
    defaultLocale,
  });
}

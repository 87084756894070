"use client";

import { useSuspenseQuery } from "@apollo/client";
import uniqBy from "lodash/uniqBy";
import { FC, PropsWithChildren, useMemo } from "react";

import { NotificationsQuery } from "@/.gql/graphql";
import { GET_NOTIFICATIONS } from "@/.graphql";

import { createCustomContext } from "./context";

/**
 * Создает React контекст для уведомлений.
 * Контекст предоставляет массив уникальных уведомлений на основе их `id`.
 */
const [Context, useNotificationsContext] = createCustomContext<
  NotificationsQuery["getNotifications"]
>({
  name: "NotificationsContext",
});
export { useNotificationsContext };

/**
 * Тип уведомления, соответствующий одному элементу из `getNotifications`.
 */
export type Notification = NotificationsQuery["getNotifications"][0];

/**
 * Провайдер для управления уведомлениями.
 *
 * Этот компонент использует Apollo Client для запроса уведомлений,
 * фильтрует дубликаты на основе `id` с помощью `lodash.uniqBy` и предоставляет
 * уникальные уведомления своим дочерним компонентам через React Context.
 *
 * @param {PropsWithChildren} props - Дочерние элементы, которым будет предоставлен контекст.
 * @returns {JSX.Element} Провайдер с уникальными уведомлениями.
 *
 * @example
 * ```tsx
 * import { NotificationsProvider } from "@/providers/NotificationsProvider";
 *
 * export default function App() {
 *   return (
 *     <NotificationsProvider>
 *       <YourAppComponents />
 *     </NotificationsProvider>
 *   );
 * }
 * ```
 */
export const NotificationsProvider: FC<PropsWithChildren> = ({ children }) => {
  // Выполняем запрос уведомлений с использованием Suspense
  const { data } = useSuspenseQuery<NotificationsQuery>(GET_NOTIFICATIONS);

  /**
   * Удаляем дубликаты уведомлений на основе `id`.
   * Мемоизируем результат для оптимизации производительности.
   */
  const uniqueNotifications = useMemo(
    () => uniqBy(data?.getNotifications, "id"),
    [data?.getNotifications],
  );

  return <Context value={uniqueNotifications}>{children}</Context>;
};

import { graphql } from "@/.gql";

export const GET_NOTIFICATIONS = graphql(`
  query Notifications {
    getNotifications {
      ...NotificationFragment
    }
  }
`);

export const GET_AI_REQUESTS = graphql(`
  query AiRequests {
    getAiRequests {
      ...TAiRequestList
    }
  }
`);

export const GET_TAGS = graphql(`
  query Tags {
    tags {
      id
      name
    }
  }
`);

export const GET_QUESTS = graphql(`
  query Quests {
    getQuests {
      id
      name
      coins
      url
      status
      step
      steps
      type
      description
    }
  }
`);

export const GET_POSTS = graphql(`
  query Posts($where: PostWhereInput!) {
    getPosts(where: $where) {
      ...PostFragment
    }
  }
`);

export const GET_RANDOOM_POSTS = graphql(`
  query RandomPosts($notId: String!) {
    randomPosts(notId: $notId) {
      ...PostFragment
    }
  }
`);

export const GET_POST = graphql(`
  query Post($url: String!, $where: ReviewWhereInput!) {
    getPost(url: $url) {
      ...PostFragment
      content {
        document(hydrateRelationships: true)
      }
      author {
        id
        name
        jobTitle
        avatar {
          id
          url
          height
          width
        }
      }
      reviews(where: $where) {
        id
        review
        rating
        nickname
      }
    }
  }
`);

export const GET_PROFILE = graphql(`
  query getProfile {
    getProfile {
      ...ProfileStaticFragment
      ...ProfileUpdatedFragment
    }
  }
`);

export const GET_WITHDRAWALS = graphql(`
  query WithdrawalsContext {
    getWithdrawals {
      meta {
        currentPage
      }
      data {
        id
        status
        amount
        createdAt
      }
    }
  }
`);

export const GET_UNDRESSINGS = graphql(`
  query getUndressings {
    getUndressings {
      ...TUndress
    }
  }
`);

export const GET_PAYMENT = graphql(`
  query getMethods {
    getMethods {
      ...TMethod
    }
    getPacks {
      coin
      currency
      discount
      hot
      isForDealers
      isPromo
      price
    }
    getSubscribePlans {
      id
      coin
      discount
      price
      currency
      type
    }
    getSubscribe {
      ...TSubscribe
    }
  }
`);

export const FIND_PAYMENT = graphql(`
  query FindPayment($id: String!) {
    getPayment(id: $id) {
      id
      amount
      paymentId
      coins
      paymentSystem
      payUrl
      isIframe
      isSubscribePlan
      amountRub
    }
  }
`);

export const GET_API_PAYMENT = graphql(`
  query getApiMethods {
    getApiMethods {
      ...TMethod
    }
    getPaymentsApi {
      updatedAt
      amount
    }
  }
`);

export const GET_VIDEO_GIVEAWAYS = graphql(`
  query getGiveawayParticipant {
    getGiveawayParticipant {
      id
      videoUrl
      mail
      status
    }
    getGiveawayParticipantSuccesCount
  }
`);

export const GET_PROMOCODE = graphql(`
  query getPromocode($promocode: String!) {
    getPromocode(promocode: $promocode) {
      ... on Promocode {
        coins
        id
        type
      }
      ... on UserPromoCodeCoins {
        coins
        id
        promoCode
        type
      }
      ... on UserPromoCodeFreeCoins {
        freeCoins
        id
        promoCode
        type
      }
    }
  }
`);

export const GET_SUBSCRIBE = graphql(`
  query getSubscribe {
    getSubscribe {
      ...TSubscribe
    }
  }
`);

export const GET_TASKS = graphql(`
  query GetTasks {
    getTasks {
      ... on MotivatedTask {
        baseUrl
        coins
        description
        expiresIn
        geoLimit {
          dailyLimit
          geo
          id
          lastResetDate
        }
        id
        status
        title
        type
      }
      ... on RetargetTask {
        baseUrl
        buttonAvailableTill
        coins
        description
        expiresIn
        geoLimit {
          dailyLimit
          geo
          id
          lastResetDate
        }
        id
        status
        title
        type
      }
    }
  }
`);

export const GET_BONUSES = graphql(`
  query GetBonuses {
    getBonuses {
      bonuses {
        ... on CoinsUserBonus {
          activatedAt
          claimAvailableAt
          coins
          createdAt
          day
          deadlineAt
          description
          id
          status
          takenAt
          type
        }
        ... on CostumeUserBonus {
          activatedAt
          claimAvailableAt
          costumeId
          costumeImage
          costumeName
          createdAt
          day
          deadlineAt
          description
          id
          status
          takenAt
          type
        }
        ... on FreeCoinsUserBonus {
          activatedAt
          claimAvailableAt
          createdAt
          day
          deadlineAt
          description
          freeCoins
          id
          status
          takenAt
          type
        }
        ... on PoseUserBonus {
          activatedAt
          claimAvailableAt
          createdAt
          day
          deadlineAt
          description
          id
          poseId
          poseImage
          poseName
          status
          takenAt
          type
        }
        ... on PromoCodeUserBonus {
          activatedAt
          claimAvailableAt
          createdAt
          day
          deadlineAt
          description
          id
          promoCode {
            ... on UserPromoCodeCoins {
              coins
              id
              promoCode
              type
            }
            ... on UserPromoCodeFreeCoins {
              freeCoins
              id
              promoCode
              type
            }
          }
          status
          takenAt
          type
        }
      }
      serverTime
    }
  }
`);

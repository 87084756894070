import deepmerge from "deepmerge";
import { getRequestConfig } from "next-intl/server";
import { notFound } from "next/navigation";

import { getCompany } from "@/utils/getCompany";

import { locales } from "./routing";
import { ERROR_IMPORT } from "./vars";

/**
 * Интерфейс для описания структуры локализационных данных.
 * @interface Messages
 */
export interface Messages {
  [key: string]: string | Messages;
}

/**
 * Конфигурация для серверной обработки локализаций.
 *
 * Этот модуль загружает и объединяет локализации в зависимости от выбранного языка пользователя (`locale`)
 * и особенностей компании, включая поддержку зеркал. Возвращает итоговую конфигурацию для использования в приложении.
 *
 * @function
 * @async
 * @param {Object} context - Контекст запроса.
 * @param {string} context.requestLocale - Язык, указанный в запросе.
 * @returns {Promise<Object>} Конфигурация для обработки локализаций.
 */
export default getRequestConfig(async ({ requestLocale }) => {
  // Получение языка из запроса
  const locale = await requestLocale;

  // Если язык не указан или не поддерживается, возвращаем 404
  if (!locale || !locales.includes(locale)) {
    notFound();
  }

  // Получение данных о компании
  const { company, companyLower, isNudiva, isDeepnudeNET, isDeepnudeIO } =
    await getCompany();

  // Загрузка локалей по умолчанию и для текущего языка
  const enLocales = await import(`../../../locales/en.json`)
    .then((module) => module.default)
    .catch((error) => {
      console.error(ERROR_IMPORT, error);
      return {};
    });

  const userLocales = await import(`../../../locales/${locale}.json`)
    .then((module) => module.default)
    .catch((error) => {
      console.error(ERROR_IMPORT, error);
      return {};
    });

  // Мерджим два набора - дополняем недостающие ключи в целевой локали
  const defaultLocales = deepmerge<Messages>(enLocales, userLocales);

  // Изначально объединённые локали равны дефолтным
  let mergedLocales = defaultLocales;

  // Проверка, является ли текущий домен зеркалом из списка
  const mirrorHost = isNudiva || isDeepnudeNET || isDeepnudeIO;

  // Если зеркало - загружаем дополнительные локали
  if (mirrorHost) {
    const mirrorEnLocales = await import(
      `../../../locales/${companyLower}/en.json`
    )
      .then((module) => module.default)
      .catch((error) => {
        console.error(ERROR_IMPORT, error);
        return {};
      });

    const mirrorUserLocales = await import(
      `../../../locales/${companyLower}/${locale}.json`
    )
      .then((module) => module.default)
      .catch((error) => {
        console.error(ERROR_IMPORT, error);
        return {};
      });

    // Мерджим два набора - дополняем недостающие ключи в целевой локали
    const mirrorLocales = deepmerge<Messages>(
      mirrorEnLocales,
      mirrorUserLocales,
    );

    // Объединяем локали - дополняем недостающие ключи в целевой локали
    mergedLocales = deepmerge<Messages>(defaultLocales, mirrorLocales);
  }

  // Возврат итоговой конфигурации
  return {
    locale, // Текущий язык
    timeZone: "Europe/Moscow", // Часовой пояс
    messages: mergedLocales, // Итоговые локали
    defaultTranslationValues: {
      company, // Имя компании
      companylower: companyLower, // Имя компании в нижнем регистре
    },
  };
});

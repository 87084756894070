"use client";

import { useQuery, useSuspenseQuery } from "@apollo/client";

import { QuestsQuery } from "@/.gql/graphql";
import { GET_QUESTS } from "@/.graphql";

import { createCustomContext } from "./context";

interface IProps {
  quests: QuestsQuery["getQuests"];
}

const [Context, useQuestsContext] = createCustomContext<IProps>({
  name: "QuestsContext",
});
export { useQuestsContext };

export type Quest = NonNullable<IProps["quests"]>[0];

export function QuestsProvider({ children }: { children?: React.ReactNode }) {
  const { data } = useSuspenseQuery(GET_QUESTS);

  useQuery(GET_QUESTS, {
    pollInterval: 10000,
  });

  const quests = data?.getQuests;

  return <Context value={{ quests }}>{children}</Context>;
}

"use client";

import { useSuspenseQuery } from "@apollo/client";

import { PostQuery } from "@/.gql/graphql";
import { GET_POST } from "@/.graphql";

import { createCustomContext } from "./context";

const [Context, usePostContext] = createCustomContext<PostQuery["getPost"]>({
  name: "PostContext",
});
export { usePostContext };

export function PostProvider({
  url,
  children,
}: {
  url: string;
  children?: React.ReactNode;
}) {
  const { data } = useSuspenseQuery(GET_POST, {
    context: {
      clientName: "blog",
    },
    variables: {
      url,
      where: {
        moderated: {
          equals: "yes",
        },
      },
    },
  });

  return <Context value={data?.getPost}>{children}</Context>;
}

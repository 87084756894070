"use client";

import { useQuery, useSuspenseQuery } from "@apollo/client";

import { GetTasksQuery } from "@/.gql/graphql";
import { GET_TASKS } from "@/.graphql";

import { createCustomContext } from "./context";

interface IProps {
  tasks: GetTasksQuery["getTasks"];
}

const [Context, useTasksContext] = createCustomContext<IProps>({
  name: "TasksContext",
});
export { useTasksContext };

export type TaskUnion = GetTasksQuery["getTasks"][0];

export function TasksProvider({ children }: { children?: React.ReactNode }) {
  const { data } = useSuspenseQuery(GET_TASKS);

  useQuery(GET_TASKS, {
    pollInterval: 10000,
  });

  const tasks = data?.getTasks;

  return <Context value={{ tasks }}>{children}</Context>;
}

"use client";

import { gql, useSuspenseQuery } from "@apollo/client";

import { createCustomContext } from "./context";

interface TMakeMoneyContext {
  sumPayments: number;
}

const GET_MAKE_MONEY = gql`
  query useMakeMoneyContext {
    sumPayments
  }
`;

const [Context, useMakeMoneyContext] = createCustomContext<TMakeMoneyContext>({
  name: "useMakeMoneyContext",
});
export { useMakeMoneyContext };

export function MakeMoneyProvider({
  children,
}: {
  children?: React.ReactNode;
}) {
  const { data } = useSuspenseQuery<TMakeMoneyContext>(GET_MAKE_MONEY);

  return <Context value={data}>{children}</Context>;
}

"use client";

import { useSuspenseQuery } from "@apollo/client";

import { TagsQuery } from "@/.gql/graphql";
import { GET_TAGS } from "@/.graphql";

import { createCustomContext } from "./context";

interface IProps {
  tags: TagsQuery["tags"];
}

const [Context, useTagsContext] = createCustomContext<IProps>({
  name: "TagsContext",
});
export { useTagsContext };

export function TagsProvider({ children }: { children?: React.ReactNode }) {
  const { data } = useSuspenseQuery(GET_TAGS, {
    context: {
      clientName: "blog",
    },
  });

  const tags = data?.tags;

  return <Context value={{ tags }}>{children}</Context>;
}

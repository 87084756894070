import { graphql } from "@/.gql";

export const MARK_AS_READ_NOTIFICATION = graphql(`
  mutation markAsReadNotification($notifId: String!) {
    markAsReadNotification(notifId: $notifId) {
      ...NotificationFragment
    }
  }
`);

export const SIGN_QUEST = graphql(`
  mutation signQuest($questId: String!) {
    signQuest(questId: $questId) {
      id
      questId
      userId
    }
  }
`);

export const SEND_REVIEW = graphql(`
  mutation sendReview(
    $review: String!
    $rating: Int!
    $postId: String!
    $nickname: String!
  ) {
    sendReview(
      review: $review
      rating: $rating
      postId: $postId
      nickname: $nickname
    ) {
      id
      review
      rating
    }
  }
`);

export const SIGN_UNDRESS = graphql(`
  mutation signUndress(
    $inputs: [InputUndress!]!
    $lastUndressingIds: [String!]
  ) {
    signUndress(inputs: $inputs, lastUndressingIds: $lastUndressingIds) {
      ...TUndress
    }
  }
`);

export const SEND_VIDEO_GIVEAWAYS = graphql(`
  mutation sendVideoGiveaways($videoUrl: String!, $mail: String!) {
    sendVideoGiveaways(videoUrl: $videoUrl, mail: $mail) {
      id
      videoUrl
      mail
      status
    }
  }
`);

export const SET_MAKE_MONEY = graphql(`
  mutation setMakeMoney {
    setMakeMoney {
      id
      isMakeMoney
    }
  }
`);

export const WITHDRAW_MONEY = graphql(`
  mutation withdrawMoney($methodName: String!, $wallet: String!) {
    withdrawMoney(methodName: $methodName, wallet: $wallet) {
      id
      makeMoneyBalance
    }
  }
`);

export const SIGN_DEALER_TRANSFER = graphql(`
  mutation signDealerTransfer($recipientId: String!, $coinPackId: String!) {
    signDealerTransfer(recipientId: $recipientId, coinPackId: $coinPackId) {
      id
      balance
    }
  }
`);

export const SIGN_RATING = graphql(`
  mutation signRating($undressId: String!, $rating: Int!) {
    signRating(undressId: $undressId, rating: $rating) {
      ...TUndress
    }
  }
`);

export const SET_AGREE = graphql(`
  mutation setAgree {
    setAgree {
      id
      isAgree
    }
  }
`);

export const SIGN_PROMOCODE = graphql(`
  mutation signPromocode($promocode: String!) {
    signPromocode(promocode: $promocode)
  }
`);

export const UNSUBSCRIBE_RECURRING = graphql(`
  mutation unsubscribeRecurring($subscribeId: String!) {
    unsubscribeRecurring(subscribeId: $subscribeId) {
      id
    }
  }
`);

export const ASSIGN_TASK = graphql(`
  mutation AssignTask($geoLimitId: String!, $taskId: String!) {
    assignTask(geoLimitId: $geoLimitId, taskId: $taskId) {
      status
    }
  }
`);

export const ASSIGN_RETARGET_TASK = graphql(`
  mutation AssignRetargetTask($retargetTaskId: String!) {
    assignRetargetTask(retargetTaskId: $retargetTaskId) {
      status
    }
  }
`);

export const COMPLETE_RETARGET_TASK = graphql(`
  mutation CompleteRetargetTask($retargetTaskId: String!) {
    completeRetargetTask(retargetTaskId: $retargetTaskId) {
      status
    }
  }
`);

export const CLAIM_BONUS = graphql(`
  mutation ClaimBonus {
    claimBonus {
      ... on CoinsUserBonus {
        activatedAt
        claimAvailableAt
        coins
        createdAt
        day
        deadlineAt
        description
        id
        status
        takenAt
        type
      }
      ... on CostumeUserBonus {
        activatedAt
        claimAvailableAt
        costumeId
        costumeImage
        costumeName
        createdAt
        day
        deadlineAt
        description
        id
        status
        takenAt
        type
      }
      ... on FreeCoinsUserBonus {
        activatedAt
        claimAvailableAt
        createdAt
        day
        deadlineAt
        description
        freeCoins
        id
        status
        takenAt
        type
      }
      ... on PoseUserBonus {
        activatedAt
        claimAvailableAt
        createdAt
        day
        deadlineAt
        description
        id
        poseId
        poseImage
        poseName
        status
        takenAt
        type
      }
      ... on PromoCodeUserBonus {
        activatedAt
        claimAvailableAt
        createdAt
        day
        deadlineAt
        description
        id
        promoCode {
          ... on UserPromoCodeCoins {
            coins
            id
            promoCode
            type
          }
          ... on UserPromoCodeFreeCoins {
            freeCoins
            id
            promoCode
            type
          }
        }
        status
        takenAt
        type
      }
    }
  }
`);

"use client";

import { useSubscription, useSuspenseQuery } from "@apollo/client";
import { FC, PropsWithChildren, useEffect } from "react";

import { GetProfileQuery } from "@/.gql/graphql";
import { GET_PROFILE, UPDATE_PROFILE } from "@/.graphql";
import { getIsPwa } from "@/utils/getIsPwa";
import { sendGTM } from "@/utils/gtm";

import { createCustomContext } from "./context";

export type Profile = GetProfileQuery["getProfile"];

const [Context, useProfileContext] = createCustomContext<Profile>({
  name: "ProfileContext",
  strict: false,
});
export { useProfileContext };

export const ProfileProvider: FC<PropsWithChildren> = ({ children }) => {
  const { data } = useSuspenseQuery(GET_PROFILE);
  useSubscription(UPDATE_PROFILE);

  useEffect(() => {
    data?.getProfile?.id &&
      sendGTM({
        event: "Match_bd",
        ecommerce: {
          usid: data?.getProfile?.id,
          ga_ab: data?.getProfile?.ga_ab,
          ga_abc: data?.getProfile?.ga_abc,
          segment_ab: data?.getProfile?.segment_ab,
          isPWA: getIsPwa(),
        },
      });
  }, [
    data?.getProfile?.ga_ab,
    data?.getProfile?.ga_abc,
    data?.getProfile?.id,
    data?.getProfile?.segment_ab,
  ]);

  useEffect(() => {
    // NOTE: надо для QA когда тестят АБ-тесты
    if (data?.getProfile?.isAdmin) {
      console.log(
        "[INFO] AB profile: ",
        JSON.stringify(
          {
            ga_ab: data?.getProfile?.ga_ab,
            ga_abc: data?.getProfile?.ga_abc,
            segment_ab: data?.getProfile?.segment_ab,
          },
          null,
          2,
        ),
      );
    }
  }, [
    data?.getProfile?.ga_ab,
    data?.getProfile?.ga_abc,
    data?.getProfile?.isAdmin,
    data?.getProfile?.segment_ab,
  ]);

  return <Context value={data?.getProfile}>{children}</Context>;
};

"use client";

import { GE } from ".constants/gtmEvents";
import undressConfig from ".constants/undress.config.json";
import {
  ApolloQueryResult,
  gql,
  useApolloClient,
  useMutation,
  useSuspenseQuery,
} from "@apollo/client";
import { Dayjs } from "dayjs";
import { filter, find, first, isEqual, map, some } from "lodash";
import { useTranslations } from "next-intl";
import {
  Dispatch,
  FC,
  PropsWithChildren,
  SetStateAction,
  SyntheticEvent,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from "react";
import { toast } from "react-toastify";

import { Exact, Gender, GetUndressingsQuery } from "@/.gql/graphql";
import { GET_UNDRESSINGS } from "@/.graphql";
import { ToastIcon } from "@/components/ToastIcon";
import { ToastMessage } from "@/components/ToastMessage";
import { useGiftContext, useGTMContext } from "@/providers";
import { generateUUID } from "@/utils/crypto";

import { useAiRequestsContext } from "./AiRequestsProvider";
import { createCustomContext } from "./context";
import { useProfileContext } from "./ProfileProvider";

/**
 * GraphQL-мутация для сброса (reset) текущих «Undress»-данных на сервере
 */
const RESET_UNDRESS = gql`
  mutation signUndress {
    resetUndress
  }
`;

/**
 * Интерфейс, описывающий параметры (настройки) для генерации «Undress»-изображения
 */
export interface PhotoSettings {
  /** Идентификатор конкретного набора настроек (для мультизагрузки) */
  id: string;
  /** Пользовательский промпт (строка) */
  prompt: string;
  /** Размер груди (идентификатор из массива busts) */
  breastSize: string;
  /** Тип тела (идентификатор из массива bodys) */
  bodyType: string;
  /** Качество (идентификатор из массива qualities) */
  quality: string;
  /** Возраст (идентификатор из массива ages) */
  age: string;
  /** Костюм (идентификатор из массива costumes) */
  costume: string;
  /** Размер «ягодиц» (идентификатор из массива butts) */
  buttSize: string;
  /** Пол (Gender.Famale / Gender.Male) */
  gender: Gender;
  /** Поза (идентификатор из массива poses или "default") */
  pose: string;
  /** Стиль (идентификатор из массива styles или "default") */
  style: string;
  /** Окружение (environment) (идентификатор из массива environments или "default") */
  environment: string;
  /** Набор (set) (идентификатор из массива sets или "default") */
  set: string;
  /** Цвет волос (hairColor) (идентификатор из массива hairColors или "default") */
  hairColor: string;
  /** Цвет кожи (skinColor) (идентификатор из массива skinColors или "default") */
  skinColor: string;
}

/**
 * Описание файла, представляющего результат «раздевания» (Undressing),
 * либо загруженный/сгенерированный файл
 */
export interface TUndressingFile {
  /** URL-адрес файла, который показывает результат генерации/загрузки */
  fileUrl: string;
  /** Уникальный идентификатор файла (совпадает с server ID) */
  id: string;
  /** Опциональное поле, указывающее на набор (set), с которым связан файл */
  set?: string;
}

/**
 * Описание объекта, представляющего локальный стейт (reUseFaceObj):
 * - `file` - файл выбранного результата из прошлой генки
 * - `lastUndressIds` - массив всех ID из прошлой генки
 */
export type TReUseFaceObj = {
  file: TUndressingFile;
  lastUndressIds: string[];
} | null;

/**
 * Тип, описывающий структуру одного Undressing-объекта из GraphQL.
 * Мы берем массив (GetUndressingsQuery["getUndressings"]) и «распаковываем» его.
 */
export type Undressing =
  NonNullable<GetUndressingsQuery["getUndressings"]> extends (infer U)[]
    ? U
    : never;

/**
 * Интерфейс, определяющий настройки для генерации «Undress»-видео
 */
export interface VideoSettings {
  /** Название или путь к видео (при наличии) */
  video: string;
  /** Длительность видео (в секундах) */
  videoDuration: number;
  /** Массив кадров (URL или base64), которые показывают визуализацию прогресса */
  videoFrames: string[];
  /** Начальная и конечная точки обрезки видео */
  videoCutPoints: [number, number];
}

/**
 * Интерфейс с методами и данными, которые контекст UndressProvider
 * предоставляет дочерним компонентам
 */
interface IProps {
  /** Массив «файлов-раздеваний» (TUndressingFile) */
  undressingsFiles: TUndressingFile[];
  /** Сеттер для массива undressingsFiles */
  setUndressingsFiles: Dispatch<SetStateAction<TUndressingFile[]>>;
  /** Массив «Undressing»-объектов, полученный с сервера (GraphQL) */
  undressings: GetUndressingsQuery["getUndressings"];
  /** Функция для рефетча undressings с сервера */
  refetch: (
    variables?: Partial<Exact<{ [key: string]: never }>> | undefined,
  ) => Promise<ApolloQueryResult<GetUndressingsQuery>>;
  /** Обработчик нажатия на "reset" (сбрасывает все состояния) */
  onReset: (e: SyntheticEvent, skipLoading?: boolean) => Promise<void>;
  /** Временное хранение результата генерации для переиспользования (TUndressingFile) и массива ID прошлых генераций */
  reUseFaceObj: TReUseFaceObj;
  /** Обработчик нажатия на "ReUse Face" (сбрасывает все состояния, сохраняет результат в отдельный локальный стейт) */
  onReUseFaceMode: (e: SyntheticEvent) => Promise<void>;
  /** Обработчик нажатия на "ReUse Face" (берет фото из `reUseFaceObj` и сохраняет в `undressingsFiles`) */
  onReUseFaceObj: () => void;
  /** Массив настроек (PhotoSettings) */
  settings: PhotoSettings[];
  /** Сеттер для массива настроек */
  setSettings: Dispatch<SetStateAction<PhotoSettings[]>>;
  /** Настройки видео (VideoSettings) */
  videoSettings: VideoSettings;
  /** Сеттер для настроек видео */
  setVideoSettings: Dispatch<SetStateAction<VideoSettings>>;
  /** Количество итемов в выбранной коллекции */
  selectedSetCount: number;
  /** Общий флаг загрузки (loading) для UI */
  loading: boolean;
  /** Сеттер флага загрузки */
  setLoading: Dispatch<SetStateAction<boolean>>;
  /** Флаг, показывающий, что у пользователя нет монет и включен блюр */
  noCoinsAndBlur: boolean;
  /** Флаг, показывающий, есть ли что «раздевать» (true, если есть хоть один undressing или файл) */
  isActive: boolean;
  /** Текущий ID выбранного файла или undressing-объекта */
  clickFileId: string;
  /** Сеттер для clickFileId */
  setClickFileId: Dispatch<SetStateAction<string>>;
  /** Функция для обновления настроек «фото» (PhotoSettings) */
  updatePhotoSetting: <K extends keyof PhotoSettings>(
    settingId: string,
    key: K,
    value: PhotoSettings[K],
  ) => void;
  /** Функция для обновления настроек «видео» (VideoSettings) */
  updateVideoSetting: <K extends keyof VideoSettings>(
    key: K,
    value: VideoSettings[K],
  ) => void;
  /** Текущее активное «фото»-файл (или Undressing), который пользователь выбрал */
  clickUndressingFile: TUndressingFile | Undressing | undefined;
  /** Текущие активные настройки (PhotoSettings) */
  clickSettings: PhotoSettings;
  /** Текущий объект Undressing (если есть) */
  clickUndress: Undressing | undefined;
  /** Статус текущей генерации */
  clickUndressStatus: {
    isInit: boolean;
    isLoading: boolean;
    isUndressing: boolean;
    isSuccess: boolean;
    isError: boolean;
    isBlur: boolean;
    isEndAllUndress: boolean;
  };
  /** Посчитанная стоимость «раздевания» по выбранным настройкам */
  undressingCost: number;
  /** Флаг, показывающий, что выбраны платные (VIP) настройки или видео */
  selectedVipSettings: boolean;
  /** Исходные (дефолтные) настройки (PhotoSettings), используемые при сбросе */
  initialSettings: PhotoSettings;
  // ** Стейт для шторки доп. настроек генерации */
  isOpenCustomizeSettings: boolean;
  /** Открыть доп. настройки генерации */
  openCustomizeSettings: () => void;
  /** Закрыть доп. настройки генерации */
  closeCustomizeSettings: () => void;
  /** Время, когда пользователь начал генерацию (используется для подсчёта таймингов) */
  startGenTime: Dayjs | null;
  /** Сеттер для startGenTime */
  setStartGenTime: Dispatch<SetStateAction<Dayjs | null>>;
  /** Лимит загрузки фотографий */
  maxFilesPhoto: number;
}

/**
 * Создаём контекст и хук для его использования: useUndressContext()
 */
const [Context, useUndressContext] = createCustomContext<IProps>({
  name: "useUndressContext",
});
export { useUndressContext };

/**
 * Провайдер UndressProvider, предоставляющий контекст для работы с функционалом «раздевания».
 * @param children Дочерние компоненты, которым требуется доступ к контексту
 */
export const UndressProvider: FC<PropsWithChildren> = ({ children }) => {
  const tToast = useTranslations("HomePage.toast");
  const profile = useProfileContext();
  const { claimedUndressGift } = useGiftContext();
  const { sendGTM } = useGTMContext();

  const aiRequests = useAiRequestsContext();
  const { cache } = useApolloClient();
  const { data, refetch } = useSuspenseQuery(GET_UNDRESSINGS);
  const [resetUndress] = useMutation(RESET_UNDRESS);

  // Извлекаем все массивы для возможных настроек (ages, bodys, и т.д.)
  const {
    ages,
    bodys,
    busts,
    butts,
    costumes: _costumes,
    environments,
    hairColors,
    poses,
    qualities,
    sets,
    skinColors,
    styles,
  } = aiRequests;

  /**
   * Расширяем массив костюмов (добавили промпт), для проверки на стоимость генерации и при наличии бонусов
   */
  const costumes = useMemo(
    () => [
      ..._costumes,
      {
        id: "prompt",
        isFree: false,
        gender: Gender.Unisex,
      },
    ],
    [_costumes],
  );

  /**
   * Проверка, что у пользователя достаточно монет для использования «платных» настроек
   */
  const userHasManyCoins = (profile?.coins ?? 0) >= undressConfig.undressCost;

  /**
   * Исходные (дефолтные) настройки для «фото»
   */
  const initialSettings: PhotoSettings = useMemo(
    () => ({
      id: "initialSettings",
      gender: Gender.Famale,
      prompt: "",
      costume:
        first(
          filter(costumes, {
            isFree: !userHasManyCoins,
            gender: Gender.Famale || Gender.Unisex,
          }),
        )?.id || "",
      breastSize: busts[1].id,
      buttSize: butts[1].id,
      quality: userHasManyCoins ? qualities[1].id : qualities[0].id,
      age: ages[1].id,
      bodyType: bodys[1].id,
      pose: "default",
      hairColor: "default",
      skinColor: "default",
      environment: "default",
      style: "default",
      set: "default",
    }),

    [ages, bodys, busts, butts, costumes, qualities, userHasManyCoins],
  );

  /**
   * Исходные (дефолтные) настройки для «видео»
   */
  const initialVideoSettings: VideoSettings = {
    video: "",
    videoDuration: 0,
    videoFrames: [],
    videoCutPoints: [0, 10],
  };

  /**
   * Состояние, хранящее ID текущего выбранного файла (или «Undressing»)
   */
  const [clickFileId, setClickFileId] = useState<string>(
    data?.getUndressings?.[0]?.id ?? "initialSettings",
  );

  /**
   * Локальный массив «сгенерированных» или загруженных файлов (Undressing Files)
   */
  const [undressingsFiles, setUndressingsFiles] = useState<TUndressingFile[]>(
    [],
  );

  /**
   * Массив настроек «PhotoSettings», соответствующих каждому Undressing
   */
  const [settings, setSettings] = useState<PhotoSettings[]>(() => {
    const undrs = data?.getUndressings ?? [{ id: "initialSettings" }];
    return undrs.map(({ id }) => ({ ...initialSettings, id }));
  });

  /**
   * Состояние с текущими настройками «VideoSettings»
   */
  const [videoSettings, setVideoSettings] =
    useState<VideoSettings>(initialVideoSettings);

  /** Флаг, показывающий «глобальную» загрузку/прогресс */
  const [loading, setLoading] = useState(false);

  /**
   * Храним время начала генерации (Dayjs), чтобы можно было отследить длительность
   */
  const [startGenTime, setStartGenTime] = useState<Dayjs | null>(null);

  /**
   * Обработчик нажатия на кнопку «reset», который сбрасывает
   * (1) состояние undressingsFiles,
   * (2) настройки,
   * (3) время начала генерации,
   * и т.д.
   * @param e SyntheticEvent для подавления всплытия ивента
   */
  const onReset = async (e: SyntheticEvent, skipLoading?: boolean) => {
    e.stopPropagation();
    setLoading(true);
    !skipLoading && sendGTM({ event: GE.UNDRESS_OTHER_PHOTO });
    setUndressingsFiles([]);
    setReUseFaceObj(null);
    setStartGenTime(null);
    setIsOpenCutomizeSettings(false);

    if (data?.getUndressings) {
      await resetUndress().catch(console.error);
    }

    cache.writeQuery({
      query: GET_UNDRESSINGS,
      data: { getUndressings: null },
    });
    setSettings([{ ...initialSettings, id: "initialSettings" }]);
    setVideoSettings(initialVideoSettings);
    setClickFileId("initialSettings");
    !skipLoading && setLoading(false);
  };

  /**
   * Временное хранение результата генерации для переиспользования
   */
  const [reUseFaceObj, setReUseFaceObj] = useState<TReUseFaceObj>(null);

  /**
   * Обработчик нажатия на кнопку «ReUse Face», который:
   * (1) вызывает `onReset`
   * (2) берет выбранный результат генерации и устанавливает в стейт
   *
   * @param e SyntheticEvent для подавления всплытия ивента
   */
  const onReUseFaceMode = async (e: SyntheticEvent) => {
    setLoading(true);

    try {
      const url = clickUndressingFile.fileUrl ?? "";

      if (!url) {
        throw new Error("No file URL");
      }

      const allLastIds = map(data?.getUndressings, "id");

      await onReset(e, true);

      setReUseFaceObj({
        file: { id: generateUUID(), fileUrl: url },
        lastUndressIds: allLastIds,
      });
      sendGTM({ event: GE.FLOW_SELECT_REUSE_FACE_AFTER_UNDRESS });
    } catch (error) {
      const err = error as Error;
      toast.error(<ToastMessage title={tToast("error")} text={err.message} />, {
        icon: ({ type }) => ToastIcon(type),
        toastId: generateUUID(),
      });
    } finally {
      setLoading(false);
    }
  };

  /**
   * Обработчик нажатия на "ReUse Face" (берет фото из `reUseFaceObj` и сохраняет в `undressingsFiles`)
   */
  const onReUseFaceObj = () => {
    try {
      if (!reUseFaceObj) {
        throw new Error("ReUse file is empty");
      }

      sendGTM({ event: GE.FLOW_START_REUSE_FACE });
      setUndressingsFiles([reUseFaceObj.file]);
    } catch (error) {
      const err = error as Error;
      toast.error(<ToastMessage title={tToast("error")} text={err.message} />, {
        icon: ({ type }) => ToastIcon(type),
        toastId: generateUUID(),
      });
    }
  };

  /**
   * «Активные» настройки. Согласно логике — это всегда settings[0],
   * если он есть, иначе мы ищем по ID, иначе берем initialSettings.
   */
  const clickSettings = useMemo(
    () =>
      settings[0] ??
      find(settings, ({ id }) => id === clickFileId) ??
      initialSettings,
    [settings, clickFileId, initialSettings],
  );

  /**
   * Флаг, показывающий, есть ли вообще что «раздевать»:
   * если на сервере есть хоть один Undressing или в локальном массиве undressingsFiles.
   */
  const isActive = useMemo(
    () => !!data?.getUndressings?.length || !!undressingsFiles.length,
    [data?.getUndressings, undressingsFiles],
  );

  /**
   * Функция для обновления настроек «фото».
   * Включает разные кейсы сброса/изменения:
   * - если key === "set"
   * - если key === "gender" и т.д.
   * @param settingId ID текущего набора настроек (но по вашей логике используется всегда первый элемент)
   * @param key Поле из PhotoSettings, которое нужно обновить
   * @param value Новое значение этого поля
   */
  const updatePhotoSetting = <K extends keyof PhotoSettings>(
    settingId: string,
    key: K,
    value: PhotoSettings[K],
  ) => {
    const mainId = settings[0]?.id ?? settingId;

    setSettings((prevSettings) =>
      prevSettings.map((prev) => {
        if (prev.id !== mainId) {
          return prev;
        }

        // 1) Смена «gender»:
        // - полный сброс к initialSettings
        // - initial для костюмов согласно гендеру
        if (key === "gender") {
          const costumeId =
            value === Gender.Male
              ? filter(
                  costumes,
                  (item) =>
                    item.gender === Gender.Male ||
                    item.gender === Gender.Unisex,
                )[0].id
              : initialSettings.costume;

          return {
            ...initialSettings,
            costume: costumeId,
            gender: value as Gender,
          };
        }

        // 2) Смена «costume»:
        // - «set» сбрасываем в `initial`
        // - «prompt» сбрасываем в `initial`
        if (key === "costume") {
          return {
            ...prev,
            [key]: value,
            set: initialSettings.set,
            prompt: initialSettings.prompt,
          };
        }

        // 3) Смена «pose» на "default": сбрасываем несколько связанных полей
        if (key === "pose" && value === initialSettings.pose) {
          return {
            ...prev,
            pose: initialSettings.pose,
            hairColor: initialSettings.hairColor,
            skinColor: initialSettings.skinColor,
            environment: initialSettings.environment,
          };
        }

        // 4) Смена «pose»:
        // - «costume» сбрасываем в `initial`, если они были `default`
        // - «set» сбрасываем в `initial`
        if (key === "pose") {
          return {
            ...prev,
            [key]: value,
            costume:
              prev.costume === "default"
                ? initialSettings.costume
                : prev.costume,
            set: initialSettings.set,
          };
        }

        // 5) Смена «set»: полный сброс к initialSettings
        if (key === "set") {
          return {
            ...initialSettings,
            costume: "default", // новый флоу: при клике по сетам - костюмы скидываем не в `initial`, а в `default`
            set: value as string,
          };
        }

        // 6) Иначе изменяем конкретное поле key
        return {
          ...prev,
          [key]: value,
        };
      }),
    );
  };

  /**
   * Функция для обновления настроек «видео»
   * @param key Поле из VideoSettings
   * @param value Новое значение
   */
  const updateVideoSetting = <K extends keyof VideoSettings>(
    key: K,
    value: VideoSettings[K],
  ) => {
    setVideoSettings((prevSettings) => ({ ...prevSettings, [key]: value }));
  };

  /**
   * Вычисляем «активный» файл (TUndressingFile | Undressing),
   * исходя из clickFileId (либо берём первый, если не найден)
   */
  const clickUndressingFile = useMemo(() => {
    const all = data?.getUndressings ?? undressingsFiles;
    return all.find(({ id }) => id === clickFileId) ?? all[0];
  }, [data?.getUndressings, undressingsFiles, clickFileId]);

  /**
   * Находим «активный» Undressing-объект (если он есть), исходя из clickFileId
   */
  const clickUndress = useMemo(() => {
    if (!data?.getUndressings) {
      return undefined;
    }
    return (
      find(data.getUndressings, ({ id }) => id === clickFileId) ??
      data.getUndressings[0]
    );
  }, [data?.getUndressings, clickFileId]);

  /**
   * Массив категорий и их настроек, чтобы понять, есть ли платные элементы
   * и считать «изменения» (отличия от initialSettings)
   */
  const categories = useMemo(
    () => [
      {
        items: ages,
        setting: clickSettings.age,
        initial: initialSettings.age,
      },
      {
        items: bodys,
        setting: clickSettings.bodyType,
        initial: initialSettings.bodyType,
      },
      {
        items: busts,
        setting: clickSettings.breastSize,
        initial: initialSettings.breastSize,
      },
      {
        items: butts,
        setting: clickSettings.buttSize,
        initial: initialSettings.buttSize,
      },
      {
        items: costumes,
        setting: clickSettings.costume,
        initial: initialSettings.costume,
      },
      {
        items: poses,
        setting: clickSettings.pose,
        initial: initialSettings.pose,
      },
      {
        items: styles,
        setting: clickSettings.style,
        initial: initialSettings.style,
      },
      {
        items: hairColors,
        setting: clickSettings.hairColor,
        initial: initialSettings.hairColor,
      },
      {
        items: skinColors,
        setting: clickSettings.skinColor,
        initial: initialSettings.skinColor,
      },
      {
        items: environments,
        setting: clickSettings.environment,
        initial: initialSettings.environment,
      },
      {
        items: qualities,
        setting: clickSettings.quality,
        initial: initialSettings.quality,
      },
      {
        items: sets,
        setting: clickSettings.set,
        initial: initialSettings.set,
      },
    ],
    [
      ages,
      bodys,
      busts,
      butts,
      costumes,
      poses,
      styles,
      hairColors,
      skinColors,
      environments,
      qualities,
      sets,
      clickSettings,
      initialSettings,
    ],
  );

  /**
   * Флаг, показывающий, выбраны ли платные (VIP) настройки
   * или есть ли генерируемое видео (videoDuration > 0)
   */
  const selectedVipSettings = useMemo(() => {
    if (videoSettings.videoDuration > 0) {
      return true;
    }
    return some(categories, ({ items, setting }) =>
      some(items, (it) => it.id === setting && !it.isFree),
    );
  }, [categories, videoSettings.videoDuration]);

  /**
   * Если у выбранного set есть collectionTag, то смотрим, сколько итемов в этой коллекции
   */
  const getCollectionCount = useCallback((items: typeof sets, id: string) => {
    const current = find(items, { id });
    return current?.collectionTag
      ? filter(items, (i) => i.collectionTag === current.collectionTag).length
      : 0;
  }, []);

  const selectedSetCount = useMemo(
    () => getCollectionCount(sets, clickSettings.set),
    [getCollectionCount, sets, clickSettings.set],
  );

  /**
   * Подсчитываем стоимость «раздевания» (undressingCost),
   * учитывая видео или VIP-настройки, а также подарки (gift).
   */
  const undressingCost = useMemo(() => {
    const undressCost = undressConfig.undressCost; // Базовая стоимость одной генерации фото
    const undressCostRemix = undressConfig.undressCostRemix; // Cтоимость одной генерации фото в режиме `ReUse Face`
    const videoCost = undressConfig.videoCost; // Базовая стоимость генерации видео за 1 секунду

    // 1. Проверяем, есть ли активные бонусы на `costume` и `pose`
    const hasCostumeBonus = claimedUndressGift.some(
      (g) =>
        g.__typename === "CostumeUserBonus" &&
        g.costumeId === clickSettings.costume,
    );
    const hasPoseBonus = claimedUndressGift.some(
      (g) =>
        g.__typename === "PoseUserBonus" && g.poseId === clickSettings.pose,
    );

    // 2. Проверяем, изменены ли другие настройки (без учета подарков)
    const hasOtherChanges = some(
      categories.filter((c) => {
        // Исключаем костюм, если есть бонус на костюм
        if (hasCostumeBonus && isEqual(c.items, costumes)) {
          return false;
        }
        // Исключаем позу, если есть бонус на позу
        if (hasPoseBonus && isEqual(c.items, poses)) {
          return false;
        }
        return true;
      }),
      (c) => c.setting !== c.initial,
    );

    // 3. Если есть хотя бы один бонус (костюм или поза) и нет других изменений — генерация бесплатная
    if ((hasCostumeBonus || hasPoseBonus) && !hasOtherChanges) {
      return 0;
    }

    // 4. Если идёт генерация видео, считаем по секундам
    if (videoSettings.videoDuration > 0) {
      const duration =
        videoSettings.videoCutPoints[1] - videoSettings.videoCutPoints[0];
      return Math.ceil(duration) * videoCost;
    }

    // 5. Если выбран `set`, стоимость умножается на количество элементов
    const itemCount = selectedSetCount || undressingsFiles.length || 1;

    // 6. Если выбран режим `ReUse Face`, берется базовая стоимость со скидкой
    if (reUseFaceObj) {
      return undressCostRemix * itemCount;
    }

    return undressCost * itemCount;
  }, [
    claimedUndressGift,
    categories,
    videoSettings.videoDuration,
    videoSettings.videoCutPoints,
    selectedSetCount,
    undressingsFiles.length,
    reUseFaceObj,
    clickSettings.costume,
    clickSettings.pose,
    costumes,
    poses,
  ]);

  /**
   * Флаг, показывающий, что у пользователя (по сути) нет монет
   */
  const noCoinsAndBlur = useMemo(() => {
    const userHasNoCoins =
      (profile?.coins ?? 0) < 1 && (profile?.freeCoins ?? 0) < 1;
    const undrs = data?.getUndressings;
    if (undrs?.some((u) => u.status === 3) && userHasNoCoins) {
      return true;
    }
    if (profile?.isBlur && userHasNoCoins) {
      return true;
    }
    return false;
  }, [
    data?.getUndressings,
    profile?.coins,
    profile?.freeCoins,
    profile?.isBlur,
  ]);

  /**
   * Стейт для шторки доп. настроек генерации
   */
  const [isOpenCustomizeSettings, setIsOpenCutomizeSettings] = useState(false);

  /**
   * Функция для открытия доп. настроек генерации
   */
  const openCustomizeSettings = () => {
    setIsOpenCutomizeSettings(true);
  };

  /**
   * Функция для закрытия доп. настроек генерации
   */
  const closeCustomizeSettings = () => {
    setIsOpenCutomizeSettings(false);
  };

  /**
   * Лимит кол-ва загружаемых файлов
   */
  const maxFilesPhoto = useMemo(
    () => (!profile || clickSettings.set !== initialSettings.set ? 1 : 9),
    [profile, clickSettings.set, initialSettings.set],
  );

  /**
   * Статус "раздевания" (число) для отображения загрузки, ошибок и т.д.
   * Возможные значения:
   * - `isInit` - "раздевания" нет - старт флоу
   * - `isLoading` - ожидаем ответ от сервера
   * - `isUndressing` - идет генерация
   * - `isSuccess` - успешно
   * - `isError` - ошибка
   * - `isBlur` - блюр-блок генки (кончились койны или вторая генка гостя)
   * - `isEndAllUndress` - все генерации завершены (результат не важен)
   */
  const clickUndressStatus = useMemo(() => {
    const undressings = data?.getUndressings ?? null;
    return {
      isInit: !clickUndress && !loading,
      isLoading: loading,
      isUndressing: clickUndress?.status === 0,
      isSuccess: clickUndress?.status === 1,
      isError: clickUndress?.status === 2,
      isBlur: clickUndress?.status === 3,
      isEndAllUndress:
        undressings?.every((undress) => undress.status > 0) ?? false,
    };
  }, [clickUndress, data?.getUndressings, loading]);

  /**
   * Отслеживаем успешные генки и пушим ивент
   */
  useEffect(() => {
    if (some(data?.getUndressings, (u) => u.status === 1)) {
      sendGTM({
        event: GE.UNDRESS_SUCCESS,
        ecommerce: {
          undress_type: data?.getUndressings?.[0].undressType || "",
        },
      });
    }
  }, [data?.getUndressings, sendGTM]);

  return (
    <Context
      value={{
        undressingsFiles,
        setUndressingsFiles,
        undressings: data?.getUndressings ?? null,
        refetch,
        onReset,
        reUseFaceObj,
        onReUseFaceMode,
        onReUseFaceObj,
        settings,
        setSettings,
        videoSettings,
        setVideoSettings,
        selectedSetCount,
        loading,
        setLoading,
        noCoinsAndBlur,
        isActive,
        clickFileId,
        setClickFileId,
        updatePhotoSetting,
        updateVideoSetting,
        undressingCost,
        selectedVipSettings,
        initialSettings,
        isOpenCustomizeSettings,
        openCustomizeSettings,
        closeCustomizeSettings,
        startGenTime,
        setStartGenTime,
        clickUndressingFile,
        clickSettings,
        clickUndress,
        clickUndressStatus,
        maxFilesPhoto,
      }}
    >
      {children}
    </Context>
  );
};

"use client";

import { Dictionary, filter, groupBy, map, isEmpty } from "lodash";
import {
  Dispatch,
  FC,
  PropsWithChildren,
  Ref,
  SetStateAction,
  useCallback,
  useMemo,
  useRef,
  useState,
} from "react";

import { AiRequestsQuery, Gender } from "@/.gql/graphql";
import { useAiRequestsContext, useUndressContext } from "@/providers";
import { createCustomContext } from "@/providers/context";

/**
 * Тип для массива запросов AI.
 */
export type AiRequestsProps =
  | AiRequestsQuery["getAiRequests"]["ages"]
  | AiRequestsQuery["getAiRequests"]["bodys"]
  | AiRequestsQuery["getAiRequests"]["busts"]
  | AiRequestsQuery["getAiRequests"]["butts"]
  | AiRequestsQuery["getAiRequests"]["costumes"]
  | AiRequestsQuery["getAiRequests"]["environments"]
  | AiRequestsQuery["getAiRequests"]["hairColors"]
  | AiRequestsQuery["getAiRequests"]["poses"]
  | AiRequestsQuery["getAiRequests"]["qualities"]
  | AiRequestsQuery["getAiRequests"]["sets"]
  | AiRequestsQuery["getAiRequests"]["skinColors"]
  | AiRequestsQuery["getAiRequests"]["styles"];

/**
 * Интерфейс пропсов провайдера.
 */
interface IConfigProviderProps {
  ages: AiRequestsProps;
  bodys: AiRequestsProps;
  busts: AiRequestsProps;
  butts: AiRequestsProps;
  costumes: AiRequestsProps;
  environments: AiRequestsProps;
  hairColors: AiRequestsProps;
  poses: AiRequestsProps;
  qualities: AiRequestsProps;
  sets: Dictionary<AiRequestsProps>;
  skinColors: AiRequestsProps;
  styles: AiRequestsProps;
  activeTabIndex: number;
  setActiveTabIndex: Dispatch<SetStateAction<number>>;
  wrapperRef: Ref<HTMLDivElement>;
  sectionsRef: React.RefObject<Map<number, HTMLDivElement | null>>;
  handleScroll: () => void;
  handleTabClick: (i: number) => () => void;
}

// Создаём контекст для управления настройками Undress
const [Context, useUndressSettingsContext] =
  createCustomContext<IConfigProviderProps>({
    name: "UndressSettingsContext",
  });

export { useUndressSettingsContext };

/**
 * Фильтрует массив объектов по полу (gender).
 * @param items - Исходный массив объектов с полем `gender`
 * @param gender - Текущий выбранный пол
 * @param filterByImage - Фильтровать ли по наличию `image`
 * @returns Отфильтрованный массив, содержащий только объекты с совпадающим `gender` или `Unisex`
 */
const filterByGender = <T extends { gender: Gender; image?: string | null }>(
  items: T[],
  gender: Gender,
  filterByImage?: boolean,
): T[] => {
  const genderFiltered = filter(
    items,
    (item) => item.gender === gender || item.gender === Gender.Unisex,
  );

  if (filterByImage) {
    return filter(genderFiltered, (item) => Boolean(item.image));
  }

  return genderFiltered;
};

/**
 * Провайдер для управления настройками Undress.
 */
export const UndressSettingsProvider: FC<PropsWithChildren> = ({
  children,
}) => {
  const {
    ages,
    bodys,
    busts,
    butts,
    costumes,
    qualities,
    styles,
    poses,
    environments,
    sets,
    hairColors,
    skinColors,
  } = useAiRequestsContext();

  const { clickSettings } = useUndressContext();
  const { gender } = clickSettings;

  // >>> Управление табами
  const [activeTabIndex, setActiveTabIndex] = useState(0);
  const wrapperRef = useRef<HTMLDivElement>(null);
  const sectionsRef = useRef(new Map<number, HTMLDivElement | null>());

  /**
   * Обрабатывает прокрутку и определяет, какая секция видима.
   */
  const handleScroll = useCallback(() => {
    if (!wrapperRef.current || !sectionsRef.current) {
      return;
    }

    const wrapperOffset = wrapperRef.current.getBoundingClientRect().top || 0;

    const sectionOffsets = map(
      Array.from(sectionsRef.current.values()),
      (section) => (section?.getBoundingClientRect().top || 0) - wrapperOffset,
    );

    if (isEmpty(sectionOffsets)) {
      return;
    }

    const target = wrapperRef.current.clientHeight / 1.5;

    const index = sectionOffsets.reduce(
      (acc, num, idx) => (num < target ? idx : acc),
      0,
    );

    setActiveTabIndex(index);
  }, [setActiveTabIndex]);

  /**
   * Обрабатывает клик по табу и прокручивает до соответствующего раздела.
   * @param index - Индекс раздела
   */
  const handleTabClick = useCallback(
    (index: number) => () => {
      const section = sectionsRef.current.get(index);
      if (section) {
        wrapperRef.current?.scrollTo({
          top: section.offsetTop - wrapperRef.current.offsetTop - 16,
          behavior: "smooth",
        });
      }
    },
    [],
  );
  // <<< Управление табами

  const settings = useMemo(
    () => ({
      costumes: filterByGender(costumes, gender, true), // Отфильтрованные массивы настроек
      bodys: filterByGender(bodys, gender, true),
      busts: filterByGender(busts, gender, true),
      butts: filterByGender(butts, gender, true),
      ages: filterByGender(ages, gender),
      qualities: filterByGender(qualities, gender),
      styles: filterByGender(styles, gender, true),
      environments: filterByGender(environments, gender, true),
      hairColors: filterByGender(hairColors, gender, true),
      skinColors: filterByGender(skinColors, gender, true),
      poses: filterByGender(poses, gender, true),
      sets: groupBy(filterByGender(sets, gender, true), "collection.en"), // Группируем сеты по коллекциям
      activeTabIndex,
      setActiveTabIndex,
      wrapperRef,
      sectionsRef,
      handleScroll,
      handleTabClick,
    }),
    [
      costumes,
      bodys,
      busts,
      butts,
      ages,
      qualities,
      styles,
      environments,
      hairColors,
      skinColors,
      poses,
      sets,
      gender,
      activeTabIndex,
      handleScroll,
      handleTabClick,
    ],
  );

  return <Context value={settings}>{children}</Context>;
};

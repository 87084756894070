"use client";

import { TRANSITION_VARIANTS } from "@cloai/uikit";
import { usePreviousValue } from "@cloai/uikit";
import { AnimatePresence, motion } from "framer-motion";
import _ from "lodash";
import { LayoutRouterContext } from "next/dist/shared/lib/app-router-context.shared-runtime";
import { useSelectedLayoutSegments } from "next/navigation";
import { PropsWithChildren, useContext } from "react";

function FrozenRouter(props: { children: React.ReactNode }) {
  const context = useContext(LayoutRouterContext);
  const prevContext = usePreviousValue(context) || null;

  const segment = _.last(useSelectedLayoutSegments());
  const prevSegment = usePreviousValue(segment);

  const changedSegment =
    segment !== prevSegment &&
    segment !== undefined &&
    prevSegment !== undefined;

  return (
    <LayoutRouterContext.Provider
      value={changedSegment ? prevContext : context}
    >
      {props.children}
    </LayoutRouterContext.Provider>
  );
}

export function RootLayoutTransition({ children }: PropsWithChildren) {
  const segment = _.last(useSelectedLayoutSegments());

  return (
    <AnimatePresence initial={false}>
      <motion.div
        key={segment}
        initial="initial"
        animate="enter"
        exit="exit"
        variants={TRANSITION_VARIANTS.pageRouting}
      >
        <FrozenRouter>{children}</FrozenRouter>
      </motion.div>
    </AnimatePresence>
  );
}

"use client";

import { FC, PropsWithChildren } from "react";

import { createCustomContext } from "./context";

type THeadersContext = Record<string, string>;

const [Context, useHeadersContext] = createCustomContext<THeadersContext>({
  name: "useHeadersContext",
});
export { useHeadersContext };

interface HeadersProviderProps extends PropsWithChildren {
  headers: Record<string, string>;
}

export const HeadersProvider: FC<HeadersProviderProps> = ({
  children,
  headers,
}) => <Context value={headers}>{children}</Context>;

"use client";

import { GE, GTMEvent } from ".constants/gtmEvents";
import { FC, PropsWithChildren, useCallback, useEffect } from "react";
import { useCookies } from "react-cookie";

import { getIsPwa } from "@/utils/getIsPwa";

import { createCustomContext } from "./context";
import { useProfileContext } from "./ProfileProvider";

interface EcommerceData {
  usid?: string | number;
  ga_ab?: number;
  ga_abc?: number;
  segment_ab?: number;
  isPWA?: boolean;
  [key: string]: unknown; // Описание для любых дополнительных данных в ecommerce
}

interface GTMData {
  event: GTMEvent;
  ecommerce?: EcommerceData;
  [key: string]: unknown; // Для других свойств, которые могут быть добавлены
}

// Интерфейс для значения контекста
interface GTMContextValue {
  sendGTM: (data: GTMData) => void;
  onSendGTM: (event: GTMEvent) => () => void;
}

// Создаём контекст с типизированным значением
const [Context, useGTMContext] = createCustomContext<GTMContextValue>({
  name: "GTMContext",
});
export { useGTMContext };

export const GTMProvider: FC<PropsWithChildren> = ({ children }) => {
  const profile = useProfileContext();
  const [cookies] = useCookies(["ga_ab", "ga_abc", "segment_ab"] as const);

  const sendGTM = useCallback(
    (data: GTMData) => {
      if (typeof window !== "undefined" && window.dataLayer) {
        try {
          // Базовые данные для ecommerce
          const baseEcommerceData = {
            usid: profile?.id,
            ga_ab: profile?.ga_ab ?? parseInt(cookies?.ga_ab),
            ga_abc: profile?.ga_abc ?? parseInt(cookies?.ga_abc),
            segment_ab: profile?.segment_ab ?? parseInt(cookies?.segment_ab),
            isPWA: getIsPwa(),
          };

          // Объединяем baseEcommerceData с переданными данными в ecommerce
          const ecommerceData = {
            ...baseEcommerceData,
            ...data.ecommerce, // дополнительные данные
          };

          // Отправляем событие в dataLayer
          window.dataLayer.push({
            ...data, // Сохраняем все свойства data (например, event)
            ecommerce: ecommerceData, // Обновленный ecommerce
          });

          if (
            process.env.NODE_ENV === "development" ||
            process.env.NEXT_PUBLIC_IS_LOCAL_TEST === "true"
          ) {
            console.log(
              "[SUCCESS] GTM data sent: ",
              JSON.stringify({ ...data, ecommerce: ecommerceData }, null, 2),
            );
          }
        } catch (error) {
          console.error("[ERROR] Failed to send GTM data:", error);
        }
      } else {
        console.warn("[WARN] GTM dataLayer is not available");
      }
    },
    [
      cookies?.ga_ab,
      cookies?.ga_abc,
      cookies?.segment_ab,
      profile?.ga_ab,
      profile?.ga_abc,
      profile?.id,
      profile?.segment_ab,
    ],
  );

  // Функция с каррированием
  const onSendGTM = useCallback(
    (event: GTMEvent) => () => {
      sendGTM({ event });
    },
    [sendGTM],
  );

  // Предоставляем обе функции через контекст
  const value: GTMContextValue = {
    sendGTM,
    onSendGTM,
  };

  // отлеживаем поведение пользователя (покинул/вернулся на вкладку)
  useEffect(() => {
    const handleVisibilityChange = () => {
      if (document.visibilityState === "hidden") {
        sendGTM({ event: GE.USER_LEFT_TAB });
      } else {
        sendGTM({ event: GE.USER_RETURNED_TAB });
      }
    };

    document.addEventListener("visibilitychange", handleVisibilityChange);

    return () => {
      document.removeEventListener("visibilitychange", handleVisibilityChange);
    };
  }, [sendGTM]);

  return <Context value={value}>{children}</Context>;
};

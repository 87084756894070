// Константы
const ONESIGNAL_SDK_ID = "onesignal-sdk";
const ONE_SIGNAL_SCRIPT_SRC =
  "https://cdn.onesignal.com/sdks/web/v16/OneSignalSDK.page.js";

let isOneSignalScriptFailed = false;

// Инициализация окружения
if (typeof window !== "undefined") {
  window.OneSignalDeferred = window.OneSignalDeferred || [];
  addSDKScript();
}

declare global {
  interface Window {
    OneSignalDeferred?: OneSignalDeferredLoadedCallback[];
    isOneSignalInitialized?: boolean;
    OneSignal?: IOneSignalOneSignal;
    safari?: {
      pushNotification: any;
    };
  }
}

/* =========================
   Вспомогательные функции
   ========================= */

function handleOnError() {
  isOneSignalScriptFailed = true;
}

function addSDKScript() {
  if (document.getElementById(ONESIGNAL_SDK_ID)) {
    return;
  }
  const script = document.createElement("script");
  script.id = ONESIGNAL_SDK_ID;
  script.defer = true;
  script.src = ONE_SIGNAL_SCRIPT_SRC;
  script.onerror = handleOnError;
  document.head.appendChild(script);
}

function invokeDeferredPromise<T>(
  executor: (OneSignal: IOneSignalOneSignal) => Promise<T>,
): Promise<T> {
  return new Promise((resolve, reject) => {
    if (isOneSignalScriptFailed) {
      return reject(new Error("OneSignal SDK script failed to load."));
    }

    try {
      window.OneSignalDeferred?.push((OneSignal: IOneSignalOneSignal) => {
        executor(OneSignal).then(resolve).catch(reject);
      });
    } catch (error) {
      reject(error);
    }
  });
}

function invokeOneSignalMethod<T>(
  method: (OneSignal: IOneSignalOneSignal) => Promise<T>,
): Promise<T> {
  return invokeDeferredPromise(method);
}

/* =========================
   Проверка поддержки push
   Код взят из BrowserSupportsPush.ts
   ========================= */

function isMacOSSafariInIframe(): boolean {
  return (
    window.top !== window &&
    navigator.vendor === "Apple Computer, Inc." &&
    navigator.platform === "MacIntel"
  );
}

function supportsSafariPush(): boolean {
  return (
    (window.safari && typeof window.safari.pushNotification !== "undefined") ||
    isMacOSSafariInIframe()
  );
}

function supportsVapidPush(): boolean {
  return (
    typeof PushSubscriptionOptions !== "undefined" &&
    Object.prototype.hasOwnProperty.call(
      PushSubscriptionOptions.prototype,
      "applicationServerKey",
    )
  );
}

function isPushNotificationsSupported() {
  return supportsVapidPush() || supportsSafariPush();
}

/* =========================
   Публичные методы
   ========================= */

const isPushSupported = (): boolean => isPushNotificationsSupported();

const init = (options: IInitObject): Promise<void> => {
  if (!options || !options.appId) {
    throw new Error("You need to provide your OneSignal appId.");
  }

  if (typeof document === "undefined") {
    return Promise.reject("Document is not defined.");
  }

  if (window?.isOneSignalInitialized) {
    return Promise.resolve();
  }

  return new Promise<void>((resolve, reject) => {
    if (isOneSignalScriptFailed) {
      return reject(new Error("OneSignal SDK script failed to load."));
    }
    window.isOneSignalInitialized = true;
    window.OneSignalDeferred?.push((OneSignal) => {
      OneSignal.init(options)
        .then(() => {
          window.isOneSignalInitialized = true;
          resolve();
        })
        .catch(reject);
    });
  });
};

/* =========================
   Логин/Логаут и согласие
   ========================= */

function oneSignalLogin(externalId: string, jwtToken?: string): Promise<void> {
  return invokeOneSignalMethod((OneSignal) =>
    OneSignal.login(externalId, jwtToken),
  );
}

function oneSignalLogout(): Promise<void> {
  return invokeOneSignalMethod((OneSignal) => OneSignal.logout());
}

function oneSignalSetConsentGiven(consent: boolean): Promise<void> {
  return invokeOneSignalMethod((OneSignal) =>
    OneSignal.setConsentGiven(consent),
  );
}

function oneSignalSetConsentRequired(requiresConsent: boolean): Promise<void> {
  return invokeOneSignalMethod((OneSignal) =>
    OneSignal.setConsentRequired(requiresConsent),
  );
}

/* =========================
   Slidedown методы
   ========================= */

function slidedownPromptPush(options?: AutoPromptOptions): Promise<void> {
  return invokeOneSignalMethod((OneSignal) =>
    OneSignal.Slidedown.promptPush(options),
  );
}

function slidedownPromptPushCategories(
  options?: AutoPromptOptions,
): Promise<void> {
  return invokeOneSignalMethod((OneSignal) =>
    OneSignal.Slidedown.promptPushCategories(options),
  );
}

function slidedownPromptSms(options?: AutoPromptOptions): Promise<void> {
  return invokeOneSignalMethod((OneSignal) =>
    OneSignal.Slidedown.promptSms(options),
  );
}

function slidedownPromptEmail(options?: AutoPromptOptions): Promise<void> {
  return invokeOneSignalMethod((OneSignal) =>
    OneSignal.Slidedown.promptEmail(options),
  );
}

function slidedownPromptSmsAndEmail(
  options?: AutoPromptOptions,
): Promise<void> {
  return invokeOneSignalMethod((OneSignal) =>
    OneSignal.Slidedown.promptSmsAndEmail(options),
  );
}

function slidedownAddEventListener(
  event: SlidedownEventName,
  listener: (wasShown: boolean) => void,
): void {
  window.OneSignalDeferred?.push((OneSignal) => {
    OneSignal.Slidedown.addEventListener(event, listener);
  });
}

function slidedownRemoveEventListener(
  event: SlidedownEventName,
  listener: (wasShown: boolean) => void,
): void {
  window.OneSignalDeferred?.push((OneSignal) => {
    OneSignal.Slidedown.removeEventListener(event, listener);
  });
}

/* =========================
   Notifications методы
   ========================= */

function notificationsSetDefaultUrl(url: string): Promise<void> {
  return invokeOneSignalMethod((OneSignal) =>
    OneSignal.Notifications.setDefaultUrl(url),
  );
}

function notificationsSetDefaultTitle(title: string): Promise<void> {
  return invokeOneSignalMethod((OneSignal) =>
    OneSignal.Notifications.setDefaultTitle(title),
  );
}

function notificationsRequestPermission(): Promise<void> {
  return invokeOneSignalMethod((OneSignal) =>
    OneSignal.Notifications.requestPermission(),
  );
}

function notificationsAddEventListener<K extends NotificationEventName>(
  event: K,
  listener: (obj: NotificationEventTypeMap[K]) => void,
): void {
  window.OneSignalDeferred?.push((OneSignal) => {
    OneSignal.Notifications.addEventListener(event, listener);
  });
}

function notificationsRemoveEventListener<K extends NotificationEventName>(
  event: K,
  listener: (obj: NotificationEventTypeMap[K]) => void,
): void {
  window.OneSignalDeferred?.push((OneSignal) => {
    OneSignal.Notifications.removeEventListener(event, listener);
  });
}

/* =========================
   Session методы
   ========================= */

function sessionSendOutcome(
  outcomeName: string,
  outcomeWeight?: number,
): Promise<void> {
  return invokeOneSignalMethod((OneSignal) =>
    OneSignal.Session.sendOutcome(outcomeName, outcomeWeight),
  );
}

function sessionSendUniqueOutcome(outcomeName: string): Promise<void> {
  return invokeOneSignalMethod((OneSignal) =>
    OneSignal.Session.sendUniqueOutcome(outcomeName),
  );
}

/* =========================
   User методы
   ========================= */

function userAddAlias(label: string, id: string): void {
  window.OneSignalDeferred?.push((OneSignal) =>
    OneSignal.User.addAlias(label, id),
  );
}

function userAddAliases(aliases: { [key: string]: string }): void {
  window.OneSignalDeferred?.push((OneSignal) =>
    OneSignal.User.addAliases(aliases),
  );
}

function userRemoveAlias(label: string): void {
  window.OneSignalDeferred?.push((OneSignal) =>
    OneSignal.User.removeAlias(label),
  );
}

function userRemoveAliases(labels: string[]): void {
  window.OneSignalDeferred?.push((OneSignal) =>
    OneSignal.User.removeAliases(labels),
  );
}

function userAddEmail(email: string): void {
  window.OneSignalDeferred?.push((OneSignal) => OneSignal.User.addEmail(email));
}

function userRemoveEmail(email: string): void {
  window.OneSignalDeferred?.push((OneSignal) =>
    OneSignal.User.removeEmail(email),
  );
}

function userAddSms(smsNumber: string): void {
  window.OneSignalDeferred?.push((OneSignal) =>
    OneSignal.User.addSms(smsNumber),
  );
}

function userRemoveSms(smsNumber: string): void {
  window.OneSignalDeferred?.push((OneSignal) =>
    OneSignal.User.removeSms(smsNumber),
  );
}

function userAddTag(key: string, value: string): void {
  window.OneSignalDeferred?.push((OneSignal) =>
    OneSignal.User.addTag(key, value),
  );
}

function userAddTags(tags: { [key: string]: string }): void {
  window.OneSignalDeferred?.push((OneSignal) => OneSignal.User.addTags(tags));
}

function userRemoveTag(key: string): void {
  window.OneSignalDeferred?.push((OneSignal) => OneSignal.User.removeTag(key));
}

function userRemoveTags(keys: string[]): void {
  window.OneSignalDeferred?.push((OneSignal) =>
    OneSignal.User.removeTags(keys),
  );
}

/* =========================
   PushSubscription методы
   ========================= */

function pushSubscriptionOptIn(): Promise<void> {
  return invokeOneSignalMethod((OneSignal) =>
    OneSignal.User.PushSubscription.optIn(),
  );
}

function pushSubscriptionOptOut(): Promise<void> {
  return invokeOneSignalMethod((OneSignal) =>
    OneSignal.User.PushSubscription.optOut(),
  );
}

function pushSubscriptionAddEventListener(
  event: "change",
  listener: (change: SubscriptionChangeEvent) => void,
): void {
  window.OneSignalDeferred?.push((OneSignal) => {
    OneSignal.User.PushSubscription.addEventListener(event, listener);
  });
}

function pushSubscriptionRemoveEventListener(
  event: "change",
  listener: (change: SubscriptionChangeEvent) => void,
): void {
  window.OneSignalDeferred?.push((OneSignal) => {
    OneSignal.User.PushSubscription.removeEventListener(event, listener);
  });
}

/* =========================
   Debug методы
   ========================= */

function debugSetLogLevel(logLevel: string): void {
  window.OneSignalDeferred?.push((OneSignal) => {
    OneSignal.Debug.setLogLevel(logLevel);
  });
}

/* =========================
   Пространства имён
   ========================= */

const PushSubscriptionNamespace: IOneSignalPushSubscription = {
  get id() {
    return window.OneSignal?.User?.PushSubscription?.id;
  },
  get token() {
    return window.OneSignal?.User?.PushSubscription?.token;
  },
  get optedIn() {
    return window.OneSignal?.User?.PushSubscription?.optedIn;
  },
  optIn: pushSubscriptionOptIn,
  optOut: pushSubscriptionOptOut,
  addEventListener: pushSubscriptionAddEventListener,
  removeEventListener: pushSubscriptionRemoveEventListener,
};

const UserNamespace: IOneSignalUser = {
  addAlias: userAddAlias,
  addAliases: userAddAliases,
  removeAlias: userRemoveAlias,
  removeAliases: userRemoveAliases,
  addEmail: userAddEmail,
  removeEmail: userRemoveEmail,
  addSms: userAddSms,
  removeSms: userRemoveSms,
  addTag: userAddTag,
  addTags: userAddTags,
  removeTag: userRemoveTag,
  removeTags: userRemoveTags,
  PushSubscription: PushSubscriptionNamespace,
};

const SessionNamespace: IOneSignalSession = {
  sendOutcome: sessionSendOutcome,
  sendUniqueOutcome: sessionSendUniqueOutcome,
};

const DebugNamespace: IOneSignalDebug = {
  setLogLevel: debugSetLogLevel,
};

const SlidedownNamespace: IOneSignalSlidedown = {
  promptPush: slidedownPromptPush,
  promptPushCategories: slidedownPromptPushCategories,
  promptSms: slidedownPromptSms,
  promptEmail: slidedownPromptEmail,
  promptSmsAndEmail: slidedownPromptSmsAndEmail,
  addEventListener: slidedownAddEventListener,
  removeEventListener: slidedownRemoveEventListener,
};

const NotificationsNamespace: IOneSignalNotifications = {
  get permissionNative() {
    return window.OneSignal?.Notifications?.permissionNative ?? "default";
  },
  get permission() {
    return window.OneSignal?.Notifications?.permission ?? false;
  },
  setDefaultUrl: notificationsSetDefaultUrl,
  setDefaultTitle: notificationsSetDefaultTitle,
  isPushSupported,
  requestPermission: notificationsRequestPermission,
  addEventListener: notificationsAddEventListener,
  removeEventListener: notificationsRemoveEventListener,
};

const OneSignalNamespace: IOneSignalOneSignal = {
  login: oneSignalLogin,
  logout: oneSignalLogout,
  init,
  setConsentGiven: oneSignalSetConsentGiven,
  setConsentRequired: oneSignalSetConsentRequired,
  Slidedown: SlidedownNamespace,
  Notifications: NotificationsNamespace,
  Session: SessionNamespace,
  User: UserNamespace,
  Debug: DebugNamespace,
};

const OneSignal = OneSignalNamespace;
export default OneSignal;

/* =========================
   Типы
   ========================= */

// Типы взяты из исходного кода. При желании их можно вынести в отдельный файл.

// ... Здесь остаются все интерфейсы и типы из исходного кода без изменений ...
interface AutoPromptOptions {
  force?: boolean;
  forceSlidedownOverNative?: boolean;
  slidedownPromptOptions?: IOneSignalAutoPromptOptions;
}
interface IOneSignalAutoPromptOptions {
  force?: boolean;
  forceSlidedownOverNative?: boolean;
  isInUpdateMode?: boolean;
  categoryOptions?: IOneSignalCategories;
}
interface IOneSignalCategories {
  positiveUpdateButton: string;
  negativeUpdateButton: string;
  savingButtonText: string;
  errorButtonText: string;
  updateMessage: string;
  tags: IOneSignalTagCategory[];
}
interface IOneSignalTagCategory {
  tag: string;
  label: string;
  checked?: boolean;
}
type PushSubscriptionNamespaceProperties = {
  id: string | null | undefined;
  token: string | null | undefined;
  optedIn: boolean;
};
type SubscriptionChangeEvent = {
  previous: PushSubscriptionNamespaceProperties;
  current: PushSubscriptionNamespaceProperties;
};
type NotificationEventName =
  | "click"
  | "foregroundWillDisplay"
  | "dismiss"
  | "permissionChange"
  | "permissionPromptDisplay";
type SlidedownEventName = "slidedownShown";
type OneSignalDeferredLoadedCallback = (onesignal: IOneSignalOneSignal) => void;

interface IOSNotification {
  readonly notificationId: string;
  readonly title?: string;
  readonly body: string;
  readonly icon?: string;
  readonly badgeIcon?: string;
  readonly image?: string;
  readonly actionButtons?: IOSNotificationActionButton[];
  readonly topic?: string;
  readonly additionalData?: object;
  readonly launchURL?: string;
  readonly confirmDelivery: boolean;
}

interface IOSNotificationActionButton {
  readonly actionId: string;
  readonly text: string;
  readonly icon?: string;
  readonly launchURL?: string;
}

interface NotificationClickResult {
  readonly actionId?: string;
  readonly url?: string;
}

type NotificationEventTypeMap = {
  click: NotificationClickEvent;
  foregroundWillDisplay: NotificationForegroundWillDisplayEvent;
  dismiss: NotificationDismissEvent;
  permissionChange: boolean;
  permissionPromptDisplay: void;
};

interface NotificationForegroundWillDisplayEvent {
  readonly notification: IOSNotification;
  preventDefault(): void;
}

interface NotificationDismissEvent {
  notification: IOSNotification;
}

interface NotificationClickEvent {
  readonly notification: IOSNotification;
  readonly result: NotificationClickResult;
}

interface IInitObject {
  appId: string;
  subdomainName?: string;
  requiresUserPrivacyConsent?: boolean;
  promptOptions?: object;
  welcomeNotification?: object;
  notifyButton?: object;
  persistNotification?: boolean;
  webhooks?: object;
  autoResubscribe?: boolean;
  autoRegister?: boolean;
  notificationClickHandlerMatch?: string;
  notificationClickHandlerAction?: string;
  serviceWorkerParam?: { scope: string };
  serviceWorkerPath?: string;
  serviceWorkerUpdaterPath?: string;
  path?: string;
  allowLocalhostAsSecureOrigin?: boolean;
  [key: string]: any;
}

interface IOneSignalOneSignal {
  Slidedown: IOneSignalSlidedown;
  Notifications: IOneSignalNotifications;
  Session: IOneSignalSession;
  User: IOneSignalUser;
  Debug: IOneSignalDebug;
  login(externalId: string, jwtToken?: string): Promise<void>;
  logout(): Promise<void>;
  init(options: IInitObject): Promise<void>;
  setConsentGiven(consent: boolean): Promise<void>;
  setConsentRequired(requiresConsent: boolean): Promise<void>;
}
interface IOneSignalNotifications {
  permissionNative: NotificationPermission;
  permission: boolean;
  setDefaultUrl(url: string): Promise<void>;
  setDefaultTitle(title: string): Promise<void>;
  isPushSupported(): boolean;
  requestPermission(): Promise<void>;
  addEventListener<K extends NotificationEventName>(
    event: K,
    listener: (obj: NotificationEventTypeMap[K]) => void,
  ): void;
  removeEventListener<K extends NotificationEventName>(
    event: K,
    listener: (obj: NotificationEventTypeMap[K]) => void,
  ): void;
}
interface IOneSignalSlidedown {
  promptPush(options?: AutoPromptOptions): Promise<void>;
  promptPushCategories(options?: AutoPromptOptions): Promise<void>;
  promptSms(options?: AutoPromptOptions): Promise<void>;
  promptEmail(options?: AutoPromptOptions): Promise<void>;
  promptSmsAndEmail(options?: AutoPromptOptions): Promise<void>;
  addEventListener(
    event: SlidedownEventName,
    listener: (wasShown: boolean) => void,
  ): void;
  removeEventListener(
    event: SlidedownEventName,
    listener: (wasShown: boolean) => void,
  ): void;
}
interface IOneSignalDebug {
  setLogLevel(logLevel: string): void;
}
interface IOneSignalSession {
  sendOutcome(outcomeName: string, outcomeWeight?: number): Promise<void>;
  sendUniqueOutcome(outcomeName: string): Promise<void>;
}
interface IOneSignalUser {
  PushSubscription: IOneSignalPushSubscription;
  addAlias(label: string, id: string): void;
  addAliases(aliases: { [key: string]: string }): void;
  removeAlias(label: string): void;
  removeAliases(labels: string[]): void;
  addEmail(email: string): void;
  removeEmail(email: string): void;
  addSms(smsNumber: string): void;
  removeSms(smsNumber: string): void;
  addTag(key: string, value: string): void;
  addTags(tags: { [key: string]: string }): void;
  removeTag(key: string): void;
  removeTags(keys: string[]): void;
}
interface IOneSignalPushSubscription {
  id: string | null | undefined;
  token: string | null | undefined;
  optedIn: boolean | undefined;
  optIn(): Promise<void>;
  optOut(): Promise<void>;
  addEventListener(
    event: "change",
    listener: (change: SubscriptionChangeEvent) => void,
  ): void;
  removeEventListener(
    event: "change",
    listener: (change: SubscriptionChangeEvent) => void,
  ): void;
}

import constantsHeaders from ".constants/headers.json";
import { faker } from "@faker-js/faker";
import { Lookup } from "geoip-lite";
import { ReadonlyHeaders } from "next/dist/server/web/spec-extension/adapters/headers";
import { cache } from "react";

import { getApiHost } from "./getApiHost";

// Деструктуризация констант из headers.json
const { HEADER_KEYS_IP, HEADER_KEYS_COUNTRY, DEFAULT_COUNTRY } =
  constantsHeaders;

// Константы для данных геолокации по умолчанию
const DEFAULT_GEO_DATA: Lookup = {
  range: [0, 0] as [number, number],
  country: "",
  region: "",
  eu: "0" as "0" | "1",
  timezone: "",
  city: "",
  ll: [0, 1],
  metro: 0,
  area: 20,
};

/**
 * Получает значение заголовка на основании списка ключей.
 *
 * @param headers Заголовки в формате ReadonlyHeaders или Record<string, string>
 * @param keys Список ключей для поиска
 * @returns Значение заголовка, если найдено, или null
 */
function getHeaderValue(
  headers: ReadonlyHeaders | Record<string, string>,
  keys: string[],
) {
  if (headers instanceof Headers) {
    for (const key of keys) {
      const value = headers.get(key);
      if (value) {
        return value;
      }
    }
  } else {
    for (const key of keys) {
      if (headers[key]) {
        return headers[key];
      }
    }
  }
  return null;
}

/**
 * Получает IP-адрес из заголовков или генерирует случайный, если он не найден.
 *
 * @param headers Заголовки в формате ReadonlyHeaders или Record<string, string>
 * @returns IP-адрес в виде строки
 */
export function getIp(
  headers: ReadonlyHeaders | Record<string, string>,
): string {
  return getHeaderValue(headers, HEADER_KEYS_IP) ?? faker.internet.ip();
}

/**
 * Получает страну из заголовков или возвращает значение по умолчанию.
 *
 * @param headers Заголовки в формате ReadonlyHeaders или Record<string, string>
 * @returns Код страны в виде строки
 */
export function getCountry(
  headers: ReadonlyHeaders | Record<string, string>,
): string {
  return getHeaderValue(headers, HEADER_KEYS_COUNTRY) ?? DEFAULT_COUNTRY;
}

/**
 * Кэшированная функция получения геоданных.
 *
 * @param headers Заголовки в формате ReadonlyHeaders или Record<string, string>
 * @returns Промис с объектом геоданных
 */
export const getGeo = cache(
  async (headers: ReadonlyHeaders | Record<string, string>) => _getGeo(headers),
);

/**
 * Получает данные геолокации на основании IP-адреса.
 *
 * @param headers Заголовки в формате ReadonlyHeaders или Record<string, string>
 * @returns Промис с объектом Lookup или данными по умолчанию
 */
export async function _getGeo(
  headers: ReadonlyHeaders | Record<string, string>,
): Promise<Lookup> {
  const ip = getIp(headers);
  const apiHost = getApiHost(headers);
  const apiUrl = `${apiHost}/check-region?ip=${ip}`;
  try {
    const response = await fetch(apiUrl);
    const data = await response.json();

    if (!data.country) {
      throw new Error("No country found");
    }

    return data;
  } catch {
    return DEFAULT_GEO_DATA;
  }
}

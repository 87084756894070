"use client";

import { useSuspenseQuery } from "@apollo/client";
import { FC, PropsWithChildren } from "react";

import { GetGiveawayParticipantQuery } from "@/.gql/graphql";
import { GET_VIDEO_GIVEAWAYS } from "@/.graphql";

import { createCustomContext } from "./context";

interface IGiveawayParticipantContext {
  giveawayParticipant: GetGiveawayParticipantQuery["getGiveawayParticipant"];
  giveawayParticipantSuccesCount: GetGiveawayParticipantQuery["getGiveawayParticipantSuccesCount"];
}

const [Context, useGiveawayParticipantContext] =
  createCustomContext<IGiveawayParticipantContext>({
    name: "GiveawayParticipantContext",
  });
export { useGiveawayParticipantContext };

export const GiveawayParticipantProvider: FC<PropsWithChildren> = ({
  children,
}) => {
  const { data } = useSuspenseQuery(GET_VIDEO_GIVEAWAYS);

  return (
    <Context
      value={{
        giveawayParticipant: data?.getGiveawayParticipant,
        giveawayParticipantSuccesCount: data?.getGiveawayParticipantSuccesCount,
      }}
    >
      {children}
    </Context>
  );
};

"use client";

import { FC, PropsWithChildren } from "react";

import { TConfig } from "@/utils/getConfig";

import { createCustomContext } from "./context";

const [Context, useConfigContext] = createCustomContext<TConfig>({
  name: "ConfigContext",
});
export { useConfigContext };

interface IConfigProviderProps extends PropsWithChildren {
  config: TConfig;
}
export const ConfigProvider: FC<IConfigProviderProps> = ({
  config,
  children,
}) => <Context value={config}>{children}</Context>;

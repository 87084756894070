import { ReadonlyHeaders } from "next/dist/server/web/spec-extension/adapters/headers";

export const getApiHost = (
  headers: ReadonlyHeaders | Record<string, string>,
) => {
  const $host =
    headers instanceof Headers ? headers.get("host") : headers["host"];
  const isNext = $host?.startsWith("next.");
  const host = $host?.replace(/^[^.]+\.([^.]+\.[a-z]+)$/, "$1");
  const isDev =
    process.env.NODE_ENV === "development" ||
    /^(beta|front|next)\./.test(host || "");
  return `https://${isNext ? "napi" : isDev ? "bapi" : "api"}.${host}`;
};

import { createContext, useContext } from "react";

/**
 * Настройки для функции `createCustomContext`.
 */
export interface CreateCusromContextOptions {
  /**
   * Определяет, нужно ли выбрасывать ошибку, если контекст оказался `null` или `undefined`.
   * По умолчанию `true`. Если установить `false`, вы сможете использовать вложенные провайдеры
   * (nested context) без выброса ошибки.
   */
  strict?: boolean;

  /**
   * Сообщение об ошибке, которое будет выброшено, если контекст не найден.
   * По умолчанию формируется сообщением, где упоминается `options.name`.
   */
  errorMessage?: string;

  /**
   * Отображаемое имя контекста (React DevTools).
   * Это необязательный параметр, который помогает отлаживать приложение.
   */
  name?: string;
}

/**
 * Кортеж, возвращаемый функцией `createCustomContext`:
 *
 * 1) `React.Context<T>` — объект контекста.
 * 2) Функция-хук, позволяющая получить доступ к контексту внутри компонентов.
 */
export type CreateCustomContextReturn<T> = [React.Context<T>, () => T];

/**
 * Создаёт контекст с указанными настройками и возвращает кортеж:
 * `[Context, useCtx]`.
 *
 * @param options Объект с настройками создания контекста {@link CreateCusromContextOptions}
 * @returns Массив [Context, useCtx], где
 * - Context — созданный контекст типа `React.Context<ContextType>`.
 * - useCtx — хук для безопасного доступа к данным контекста.
 *
 * @typeParam ContextType Тип данных, который будет храниться в контексте.
 *
 * Пример использования:
 * ```tsx
 * // 1) Создаём контекст и хук:
 * const [Context, useUserContext] = createCustomContext<User>({ name: "UserContext" });
 * export { useUserContext };
 *
 * // 2) Создаём провайдер:
 * export const UserProvider: FC<PropsWithChildren> = ({ children }) => {
 *    const { data } = useSuspenseQuery(GET_PROFILE);
 *    return <Context value={data?.user}>{children}</Context>;
 * };
 *
 * // 3) Используем провайдер:
 * function App() {
 *   return (
 *     <UserProvider>
 *       <HomePage />
 *     </UserProvider>
 *   );
 * }
 *
 * // 4) Достаём данные из контекста в любом компоненте:
 * function HomePage() {
 *   const user = useUserContext();
 *   return <p>Hello, {user.name}!</p>;
 * }
 * ```
 */
export function createCustomContext<ContextType>(
  options: CreateCusromContextOptions = {},
) {
  // Деструктурируем настройки, устанавливаем значения по умолчанию
  const {
    // Если strict = true, выбросим ошибку при отсутствии контекста
    strict = true,
    // Сообщение об ошибке по умолчанию
    errorMessage = `${options.name}: \`context\` is undefined. Seems you forgot to wrap component within the Provider`,
    // Отображаемое имя контекста в React DevTools
    name,
  } = options;

  /**
   * Создаём сам контекст. Тип контекста — `ContextType | undefined`,
   * чтобы можно было отследить отсутствие значения при строгом режиме.
   */
  const Context = createContext<ContextType | undefined>(undefined);

  /**
   * Устанавливаем `displayName`, чтобы при отладке в React DevTools
   * контекст отображался как `name` (например, "UserContext").
   */
  Context.displayName = name;

  /**
   * Кастомный хук, позволяющий получить значение из контекста.
   * При этом, если `context` не установлен и включён строгий режим,
   * выбрасываем ошибку с заданным сообщением.
   */
  function useCtx() {
    // Достаём текущее значение контекста
    const context = useContext(Context);

    // Если контекст отсутствует и strict = true, кидаем ошибку
    if (!context && strict) {
      const error = new Error(errorMessage);
      error.name = "ContextError";
      // Захватываем стек, чтобы проще было отлаживать
      Error.captureStackTrace?.(error);
      throw error;
    }

    // Если контекст присутствует, возвращаем его
    return context;
  }

  /**
   * Возвращаем кортеж из самого контекста и хука-доступа к нему.
   * Пример: `[UserContext, useUserContext]`.
   */
  return [Context, useCtx] as CreateCustomContextReturn<ContextType>;
}

export const GE = {
  ANIMATE_POPUP__CLICK_ANIMATE: "Animate_popup__click_animate",
  ANIMATE_POPUP__NOT_ENOUGH_COINS_BUY_VIP_COIN:
    "Animate_popup__Not_enough_coins_Buy_vip_coin",
  AUTH_VIA_PASSKEY__REGISTER: "auth_via_passkey__register",
  BANNER_BDSM: "Banner_BDSM",
  BANNER_BUY_VIP_COIN: "Banner_buy_vip_coin",
  BANNER_DRAWNUDES: "Banner_Drawnudes",
  BANNER_FREEBONUS__FREE: "Banner_FreeBonus__FREE",
  BANNER_FREEBONUS__VIP: "Banner_FreeBonus__VIP",
  BANNER_GOOGLE_FORM: "Banner_Google_Form",
  BANNER_HOTGENS: "Banner_Hotgens",
  BANNER_INSTAGRAM: "Banner_instagram",
  BANNER_SWAPERFACE: "Banner_Swaperface",
  BANNER_SWEETCHAT: "Banner_Sweetchat",
  BANNER_VIDEO_UPLOAD: "Banner_Video-upload",
  BEGIN_CHECKOUT_API: "begin_checkout_api",
  BEGIN_CHECKOUT: "begin_checkout",
  BLOG_POST_CLICK_LEAVE_FEEDBACK__GUEST:
    "blog_post_click_leave_feedback__guest",
  BLOG_POST_CLICK_LEAVE_FEEDBACK: "blog_post_click_leave_feedback",
  BLOG_POST_MODAL_LEAVE_FEEDBACK: "blog_post_modal_leave_feedback",
  BLOG_POST_SHARE_TO_SOCIAL: "blog_post_share_to_social",
  BLOG_POST_TRY_UNDRESS: "blog_post_try_undress",
  BURGER_BUY_VIP_COIN: "Burger_Buy_vip_coin",
  BURGER_EARN_COINS_BUTTON: "Burger_earn_coins_button",
  CHANGE_LANGUAGE: "change_language",
  CHOICE_PAYMENT_API: "choice_payment_api",
  CHOICE_PAYMENT: "choice_payment",
  CHOICE_VALUE_MODAL_PACKAGES: "choice_value_Modal_packages",
  CHOICE_VALUE: "choice_value",
  CHOOSE_LANGUAGE_BURGER: "choose_language_burger",
  CHOOSE_LANGUAGE_FOOTER: "choose_language_footer",
  CHOOSE_LANGUAGE_HEADER: "choose_language_header",
  CHOOSE_TG: "choosetg",
  CHOOSETRYFREE: "chooseTryFree",
  CLICK_CLAIM_GIFT: "click_claim_gift",
  CLICK_INSTALL_PWA__NATIVE: "click_install-PWA__native",
  CLICK_INSTALL_PWA__POPUP: "click_install-PWA__popup",
  CLICK_TUTOR__HOMEPAGE: "click_tutor__homepage",
  CONFIRM_SUBSCRIBE_FREE: "confirm-subscribe-free",
  CONFIRM_SUBSCRIBE_VIP: "confirm-subscribe-vip",
  CONTACT_THE_DEALER: "Contact_the_dealer",
  DOWNLOAD_IMAGE: "Download_Image",
  DOWNLOAD_VIDEO: "Download_Video",
  EARN_COINS_BUTTON: "earn_coins_button",
  FLOW__CLICK_ANIMATE: "Flow__click_animate",
  FLOW__GENERATE_ERROR: "Flow__generate_ERROR",
  FLOW_ERROR_UNDRESS__REDIRECT_TO_AUTH: "Flow_error_undress__redirect_to_auth",
  FLOW_ERROR_USER_IS_BLOCKED: "Flow_ERROR_USER_IS_BLOCKED",
  FLOW_NO_COINS_POPUP_EARN_COINS_BUTTON:
    "Flow_No_coins_popup_earn_coins_button",
  FLOW_NOT_ENOUGH_COINS__REDIRECT_TO_BUY:
    "Flow_Not_enough_coins__redirect_to_buy",
  FLOW_NOT_ENOUGH_COINS_BUY_VIP_COIN: "Flow_Not_enough_coins_Buy_vip_coin",
  FLOW_SELECT_REUSE_FACE_AFTER_UNDRESS: "Flow_select_reuse_face_after_undress",
  FLOW_START_CLICK_UPLOAD_FILE: "Flow_start_click_upload_file",
  FLOW_START_CLICK_UPLOAD_INSTAGRAM: "Flow_start_click_upload_instagram",
  FLOW_START_REUSE_FACE: "Flow_start_reuse_face",
  GIFT_BUTTON: "gift_button",
  HEADER_BUY_VIP_COIN: "Header_Buy_vip_coin",
  HEADER_EARN_COINS_BUTTON: "Header_earn_coins_button",
  LANDING_ADVANTAGE_EARN_COINS_BUTTON: "Landing_Advantage_earn_coins_button",
  LANDING_PACKAGE_CHOICE_VALUE: "Landing_package_choice_value",
  PAYHOLD_MODAL__CLICK_PACKEGE: "payhold_modal__click_packege",
  PAYHOLD_MODAL__SHOW: "payhold_modal__show",
  PAYMENT_FAILED_AND_REDIRECT: "payment_failed_and_redirect",
  POPAP_MORE_MONEY: "Popap_more_money",
  QR_GET_APP_SCANNED: "qr_get_app_scanned",
  RATE_RESULT: "rate_result",
  SUBSCRIBE_FREE__SHOW: "subscribe-free__show",
  SUBSCRIBE_VIP__SHOW: "subscribe-vip__show",
  TUTOR_READED: "tutor_readed",
  UNDRESS_GUEST_TO_AUTH: "Undress_Guest_to_Auth",
  UNDRESS_GUEST: "Undress_Guest",
  UNDRESS_OTHER_PHOTO: "Undress_other_photo",
  UNDRESS_SUCCESS: "Undress_success",
  UNDRESS_VIP_COIN: "Undress_vip_coin",
  UNDRESS: "Undress",
  UPLOAD_PHOTO__UPLOADBLOCK: "upload_photo__uploadblock",
  UPLOAD_PHOTO_FROM_INSTAGRAM: "upload_photo_from_instagram",
  UPLOAD_PHOTO: "upload_photo",
  UPLOAD_VIDEO__UPLOADBLOCK: "upload_video__uploadblock",
  USER_LEFT_TAB: "user_left_tab",
  USER_RETURNED_TAB: "user_returned_tab",
  USER_CHOOSE_BROWSER_LANG: "user_choose_browser_lang",
  USER_CANCEL_BROWSER_LANG: "user_cancel_browser_lang",
} as const;

// Базовый тип событий из GTM_EVENTS
export type BaseGTMEvent = (typeof GE)[keyof typeof GE];

// Расширенный тип с поддержкой "динамических" событий
export type GTMEvent =
  | BaseGTMEvent
  | `auth-${string}`
  | `gift_button_${"header" | "burger"}`
  | `Click_Task_earn_coins__${string}`;

export function ToastIcon(type: string) {
  switch (type) {
    case "info":
      return (
        <div
          className="material-icons flex items-center text-(--toastify-icon-color-info)"
          data-icon="info"
        />
      );
    case "error":
      return (
        <div
          className="material-icons flex items-center text-(--toastify-icon-color-error)"
          data-icon="error"
        />
      );
    case "success":
      return (
        <div
          className="material-icons flex items-center text-(--toastify-icon-color-success)"
          data-icon="check_circle"
        />
      );
    case "warning":
      return (
        <div
          className="material-icons flex items-center text-(--toastify-icon-color-warning)"
          data-icon="warning"
        />
      );
    case "free":
      return (
        <div
          className="material-icons flex items-center text-secondary"
          data-icon="star"
        />
      );
    case "vip":
      return (
        <div
          className="material-icons flex items-center text-primary"
          data-icon="diamond"
        />
      );
    default:
      return (
        <div
          className="material-icons flex items-center text-(--toastify-icon-color-info)"
          data-icon={type}
        />
      );
  }
}

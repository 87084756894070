"use client";

import { useSuspenseQuery } from "@apollo/client";
import {
  Dispatch,
  FC,
  PropsWithChildren,
  SetStateAction,
  useState,
} from "react";

import { GetApiMethodsQuery } from "@/.gql/graphql";
import { GET_API_PAYMENT } from "@/.graphql";

import { createCustomContext } from "./context";

interface TApiPaymentContextProps {
  methods: GetApiMethodsQuery["getApiMethods"];
  paymentsApi: GetApiMethodsQuery["getPaymentsApi"];
  loading: boolean;
  setLoading: Dispatch<SetStateAction<boolean>>;
}

const [Context, useApiPaymentContext] =
  createCustomContext<TApiPaymentContextProps>({
    name: "useApiPaymentContext",
  });
export { useApiPaymentContext };

export const ApiPaymentProvider: FC<PropsWithChildren> = ({ children }) => {
  const { data } = useSuspenseQuery(GET_API_PAYMENT);
  const [loading, setLoading] = useState(false);

  return (
    <Context
      value={{
        methods: data?.getApiMethods,
        paymentsApi: data?.getPaymentsApi,
        loading,
        setLoading,
      }}
    >
      {children}
    </Context>
  );
};

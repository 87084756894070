import { gql } from "@apollo/client";

export const PROFILE_FRAGMENT_STATIC = gql`
  fragment ProfileStaticFragment on Profile {
    apiKey
    apiLimit
    apiPricePerGen
    country
    earnedVipCoins
    email
    ga_ab
    ga_abc
    isAdmin
    isDealer
    isMakeMoneyApi
    isPlayedGiveaway
    isShowAllMethods
    isShowApi
    isSubscribedApi
    makeMoneyPercent
    phone
    randomNumber
    referralLink
    segment_ab
    subscribeApiEndAt
  }
`;

export const PROFILE_FRAGMENT_UPDATED = gql`
  fragment ProfileUpdatedFragment on Profile {
    apiBalance
    balance
    coins
    completedQuestsCount
    countReferrals
    freeCoins
    id
    isAgree
    isBlockedUndress
    isBlur
    isEmailSubscribed
    isMakeMoney
    isWasFirstUndress
    makeMoneyBalance
    paymentCount
    subscribes
    sumCoinsCompletedQuest
    updatedAt
  }
`;

export const NOTIFICATION_FRAGMENT = gql`
  fragment NotificationFragment on Notification {
    id
    props {
      ... on DefaultNotification {
        text
        title
      }
      ... on PaymentNotification {
        coins
        text
        title
        type
        typeCoins
      }
      ... on PortalNotification {
        content
        title
      }
    }
    type
    readAt
    createdAt
  }
`;

export const FRAGMENT_AI_REQUEST = gql`
  fragment TAiRequest on AiRequest {
    category
    category
    collection
    collectionTag
    gender
    id
    image
    isFree
    isTop
    isNew
    icon
    name
    icon
    position
    subCategory
    type
  }
`;

export const FRAGMENT_AI_REQUEST_LIST = gql`
  fragment TAiRequestList on AiRequests {
    ages {
      ...TAiRequest
    }
    costumes {
      ...TAiRequest
    }
    bodys {
      ...TAiRequest
    }
    busts {
      ...TAiRequest
    }
    butts {
      ...TAiRequest
    }
    poses {
      ...TAiRequest
    }
    qualities {
      ...TAiRequest
    }
    environments {
      ...TAiRequest
    }
    styles {
      ...TAiRequest
    }
    sets {
      ...TAiRequest
    }
    hairColors {
      ...TAiRequest
    }
    skinColors {
      ...TAiRequest
    }
  }
`;

export const UNDRESS_FRAGMENT = gql`
  fragment TUndress on Undress {
    id
    status
    position
    successAt
    fileUrl
    isFree
    isBlur
    isPaid
    isGuest
    error
    rating
    undressType
  }
`;

export const FRAGMENT_SUBSCRIBE = gql`
  fragment TSubscribe on Subscribe {
    id
    subscribeEndAt
    SubscribePlan {
      id
      type
    }
  }
`;

export const FRAGMENT_METHOD = gql`
  fragment TMethod on Method {
    id
    isBankCard
    name
    props
    image
    allowedCountries
    isP2P
    paymentSystemId
    key
    isDealer
    position
    isCrypto
    disabledCountries
  }
`;

export const POST_FRAGMENT = gql`
  fragment PostFragment on Post {
    id
    title
    preview {
      id
      url
      width
      height
    }
    tags {
      id
      name
    }
    publishDate
    seoTitle
    seoDescription
    url
    articleBody
  }
`;

export const BONUS_FRAGMENT = `
  activatedAt
  createdAt
  deadlineAt
  id
  takenAt
  type
`;

export const COINS_USER_FRAGMENT = gql`
  fragment TCoinsUserBonus on CoinsUserBonus {
    ${BONUS_FRAGMENT}
    coins
  }
`;

export const FREECOINS_USER_FRAGMENT = gql`
  fragment TFreeCoinsUserBonus on FreeCoinsUserBonus {
    ${BONUS_FRAGMENT}
    freeCoins
  }
`;

"use client";

import { useSuspenseQuery } from "@apollo/client";
import { useParams, useSearchParams } from "next/navigation";

import { PostsQuery } from "@/.gql/graphql";
import { GET_POSTS } from "@/.graphql";

import { createCustomContext } from "./context";

interface IProps {
  posts: PostsQuery["getPosts"];
}

const [Context, usePostsContext] = createCustomContext<IProps>({
  name: "PostsContext",
});
export { usePostsContext };

export function PostsProvider({ children }: { children?: React.ReactNode }) {
  const searchParams = useSearchParams();
  const params = useParams();
  const tagsFilter = params?.tag;
  const lastId = searchParams.get("lastId");

  const where: Record<string, unknown> = {};
  const cursor = lastId ? { id: lastId } : undefined;
  if (tagsFilter) {
    where["tags"] = {
      some: {
        name: {
          mode: "insensitive",
          contains: tagsFilter,
        },
      },
    };
  }

  const { data } = useSuspenseQuery(GET_POSTS, {
    context: {
      clientName: "blog",
    },
    variables: {
      where,
      cursor,
    },
  });

  const posts = data?.getPosts ?? [];

  return <Context value={{ posts }}>{children}</Context>;
}

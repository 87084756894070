"use client";

import { FC, PropsWithChildren, useEffect, useState } from "react";
import { useWindowSize } from "react-use";

import { createCustomContext } from "./context";
import { useHeadersContext } from "./HeadersProvider";

type TProps = {
  isMobiles: boolean;
};

const [Context, useMobileContext] = createCustomContext<TProps>({
  name: "MobileContext",
});
export { useMobileContext };

export const MobileProvider: FC<PropsWithChildren> = ({ children }) => {
  const headers = useHeadersContext();
  const { width } = useWindowSize();
  const [isMobiles, toogleIsMobiles] = useState(
    !!String(headers["user-agent"]).match(
      /Android|BlackBerry|iPhone|iPod|Opera Mini|IEMobile|WPDesktop/i,
    ),
  );

  useEffect(() => {
    toogleIsMobiles(width < 920);
  }, [width]);

  return <Context value={{ isMobiles }}>{children}</Context>;
};

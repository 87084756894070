"use client";

import { ApolloLink, HttpLink, split } from "@apollo/client";
import { loadDevMessages, loadErrorMessages } from "@apollo/client/dev";
import { GraphQLWsLink } from "@apollo/client/link/subscriptions";
import { getMainDefinition } from "@apollo/client/utilities";
import {
  ApolloClient,
  ApolloNextAppProvider,
  InMemoryCache,
  SSRMultipartLink,
} from "@apollo/experimental-nextjs-app-support";
import createUploadLink from "apollo-upload-client/createUploadLink.mjs";
import { createClient } from "graphql-ws";
import { PropsWithChildren, useCallback } from "react";
import { useCookies } from "react-cookie";
import { setVerbosity } from "ts-invariant";

import { useHeadersContext } from "@/providers";
import { getCountry, getIp } from "@/utils/headers";

if (process.env.NODE_ENV === "development") {
  // Adds messages only in a dev environment
  loadDevMessages();
  loadErrorMessages();
  setVerbosity("error");
}

function makeClient(
  authToken: string,
  headers: Record<string, string>,
  cookies: Record<string, string>,
) {
  const {
    "user-agent": userAgent,
    host,
    ["x-forwarded-proto"]: protocol,
  } = headers;
  const isLocal = protocol === "http" || host.includes("localhost");
  const urlApi = isLocal ? "bapi.clothoff.org" : host;
  const ip = getIp(headers);
  const country = getCountry(headers);

  const defaultHeaders = {
    "x-auth-token": authToken,
    "x-auth-ip": ip,
    "x-country": country,
    "x-user-agent": userAgent,
    "x-segment-ab": cookies.segment_ab,
    "x-ga-ab": cookies.ga_ab,
    "x-ga-abc": cookies.ga_abc,
  };

  const wsLink = new GraphQLWsLink(
    createClient({
      url: `wss://${urlApi}/graphql`,
      connectionParams: {
        authToken,
      },
    }),
  );

  const blogLink = new HttpLink({
    uri: "https://api-b.clothoff.net",
    headers: defaultHeaders,
  });

  const uploadLink = createUploadLink({
    uri: `https://${urlApi}/graphql`,
    fetchOptions: { cache: "no-store" },
    headers: defaultHeaders,
  });

  const httpLink = split(
    (operation) => operation.getContext().clientName === "blog",
    blogLink,
    uploadLink as unknown as ApolloLink,
  );

  const link = split(
    ({ query }) => {
      const definition = getMainDefinition(query);
      return (
        definition.kind === "OperationDefinition" &&
        definition.operation === "subscription"
      );
    },
    wsLink,
    httpLink,
  );

  return new ApolloClient({
    connectToDevTools: true,
    cache: new InMemoryCache(),
    link:
      typeof window === "undefined"
        ? ApolloLink.from([
            new SSRMultipartLink({
              stripDefer: true,
            }),
            httpLink,
          ])
        : link,
  });
}

interface IApolloProviderProps extends PropsWithChildren {
  authToken: string;
}

export function ApolloProvider({ children, authToken }: IApolloProviderProps) {
  const headers = useHeadersContext();
  const [cookies] = useCookies(["segment_ab", "ga_ab", "ga_abc"]);
  const createClientCallback = useCallback(
    () => makeClient(authToken, headers, cookies),
    [authToken, headers, cookies],
  );

  return (
    <ApolloNextAppProvider makeClient={createClientCallback}>
      {children}
    </ApolloNextAppProvider>
  );
}
